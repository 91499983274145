<script lang="ts">
  import Container from '@components/container/Container.svelte'

  export let totalSteps: number = 2
  export let step: number = 0
  export let widthParam = ''
  $: {
    widthParam = `width: ${((step + 1) / totalSteps) * 100}%;`
  }
</script>

<Container>
  <div class="crtw-h-1.5 crtw-overflow-hidden crtw-bg-gray-100 crtw-rounded-lg crtw-my-2.5">
    <div class="crtw-h-1.5 crtw-bg-progressColor crtw-rounded-lg" style={widthParam} />
  </div>
</Container>
