import { Chains } from '@src/Passport.enums'

export const config = {
  testnet: {
    credenza: {
      apiUrl: 'https://api.testnets.credenza.online',
      gitbookUrl: `https://developer.credenza3.com/credenza-sdk-v3.0/`,
    },
    stripe: {
      pk: 'pk_test_51LJ0nZHfOaPNdSbZUzB3H4D44JoFD0AIfYv4QLSSSxrBs8ch4A5UlpJOXF4OG0fkgppBD2jOrDTCNaGzevnxdH2200rI1wY1cC',
    },
  },
  mainnet: {
    credenza: {
      apiUrl: 'https://api.credenza.online',
      gitbookUrl: `https://developer.credenza3.com/credenza-sdk-v3.0/`,
    },
    stripe: {
      pk: 'pk_live_51LJ0nZHfOaPNdSbZpj7gaAUfmbfWylFaORiGxRxyVn8efm1hMTP6Cz2ihL1AhEcUu9AyqTKsUblMQL5m0YU6HTg3001izgq3Fj',
    },
  },
}

export const erc20CredTokens = {
  [Chains.POLYGON_MUMBAI]: {
    address: '0x7a0016F372348ca6AEeE7c8B1B02E37C64818f44',
    decimals: 6,
  },
  [Chains.POLYGON]: {
    address: '0x9dc9421D625b734d339357b97d3480eab95B1423',
    decimals: 6,
  },
  [Chains.CHILIZ_SPICY]: {
    address: '0x5619A31C5776c50e4A3f6DD3E07be13f4efa211C',
    decimals: 6,
  },
  [Chains.CHILIZ]: {
    address: '0x5619A31C5776c50e4A3f6DD3E07be13f4efa211C',
    decimals: 6,
  },
} as const

export const membershipContracts = {
  [Chains.POLYGON_MUMBAI]: {
    address: '0xF1196d2BAeF7c5cC72a5898639b9767A17E5bb2F',
  },
  [Chains.POLYGON]: {
    address: '0xae7716da2d2d8269466da09d2121ef976c797da9',
  },
  [Chains.CHILIZ_SPICY]: {
    address: '0x1E483507E4842E3C71EC6BcD85c6d2790B63f890',
  },
  [Chains.CHILIZ]: {
    address: '0x0E53B4BFf707d9BE5582D9D2a4e6c00dc5Fca16F',
  },
} as const
