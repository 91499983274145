export const stripeStyles = {
  base: {
    color: '#121e2b',
    fontSize: '12px',
    '::placeholder': {
      color: '#d9d9d9',
    },
  },
  invalid: {
    color: '#fa755a',
  },
}
