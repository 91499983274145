import { get } from 'svelte/store'
import { configStore, userAddressStore, providerStore, ppaStore } from '@stores/passport-store'
import { ethers } from '@credenza3/core-web-evm-ext'

type TNftConfig = {
  address: string
  tokens: string[]
  type: 'ERC1155' | 'ERC721'
}

type TMembershipConfig = {
  address: string
  owner: string
}

type TSetFn = (val: { loading: boolean; active: boolean }) => void
type TUpdateFn = (val: { loading?: boolean; active?: boolean }) => void

const checkNft = async (nftConfigArr: TNftConfig[] = [], set: TSetFn) => {
  if (!nftConfigArr?.length) return false

  const provider = get(providerStore)
  const userAddress = await get(userAddressStore)

  for (const nftConfig of nftConfigArr) {
    if (nftConfig?.type === 'ERC721') {
      const { erc721MinAbi } = await import('@src/Passport.abi')
      const contract = new ethers.Contract(nftConfig.address, erc721MinAbi, provider)
      for (const tokenId of nftConfig.tokens) {
        const owner = await contract.ownerOf(tokenId)
        if (owner === userAddress) {
          set({ loading: false, active: false })
          return true
        }
      }
    } else {
      //1155 by default
      const { erc1155MinAbi } = await import('@src/Passport.abi')
      const contract = new ethers.Contract(nftConfig.address, erc1155MinAbi, provider)
      const [balance] = await contract.balanceOfBatch([userAddress], nftConfig.tokens)
      if (balance > 0) {
        set({ loading: false, active: false })
        return true
      }
    }
  }
  return false
}

const checkMembership = async (membershipConfigArr: TMembershipConfig[] = [], set: TSetFn) => {
  if (!membershipConfigArr?.length) return false

  const provider = get(providerStore)

  for (const membershipConfig of membershipConfigArr) {
    const { membershipMinAbi } = await import('@src/Passport.abi')
    const contract = new ethers.Contract(membershipConfig.address, membershipMinAbi, provider)
    const isMember = await contract.confirmMembership(membershipConfig.owner)
    if (isMember) {
      set({ loading: false, active: false })
      return true
    }
  }
  return false
}

const checkPayPerArticleState = async (currentPath: string, set: TSetFn, update: TUpdateFn) => {
  const { payPerArticle } = get(configStore)?.content || {}

  for (const articleConfig of payPerArticle || []) {
    const uriMasks = articleConfig.uriMasks || []
    if (!uriMasks.length) continue

    for (const mask of uriMasks) {
      if (!currentPath.includes(mask)) continue

      set({ loading: true, active: true })
      if (await checkNft(articleConfig.nfts || [], set)) return
      if (await checkMembership(articleConfig.memberships || [], set)) return
    }
    update({ loading: false })
  }
}

let isPPAObserverCreated = false
export const createPayPeyArticleObserver = () => {
  if (isPPAObserverCreated) return
  isPPAObserverCreated = true

  const { update, set } = ppaStore
  const observable = () => document.location.pathname + document.location.search
  let currentPathValue: string | null = null

  const observer = new MutationObserver(() => {
    const newPathValue = observable()
    if (currentPathValue === newPathValue) return
    currentPathValue = newPathValue
    void checkPayPerArticleState(currentPathValue, set, (partialVal) =>
      update((prevVal) => ({ ...prevVal, ...partialVal })),
    )
  })

  providerStore.subscribe((provider) => {
    if (!provider) {
      observer.disconnect()
      return
    }
    observer.observe(document.body, { childList: true, subtree: true })
  })
}
