<script lang="ts">
  import { userStore, userAddressStore } from '@stores/passport-store'

  export let balance: string | null = null
</script>

<div
  class="crtw-flex crtw-justify-center crtw-flex-col crtw-items-start crtw-gap-[22px] crtw-w-full crtw-px-2.5 crtw-py-0"
>
  {#if $userStore?.name}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">User name:</p>
      <p class="crtw-text-sm">
        {$userStore.name}
      </p>
    </div>
  {/if}
  {#if $userStore?.email || $userStore?.pending?.email}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">Email address:</p>
      <p class="crtw-text-sm">
        {#if $userStore?.email}
          {$userStore.email}
        {:else if $userStore?.pending?.email}
          {$userStore.pending?.email} (Unconfirmed)
        {/if}
      </p>
    </div>
  {/if}

  {#if $userStore?.phone || $userStore?.pending?.phone}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">Phone number:</p>
      <p class="crtw-text-sm">
        {#if $userStore?.phone}
          {$userStore.phone}
        {:else if $userStore?.pending?.phone}
          {$userStore.pending?.phone} (Unconfirmed)
        {/if}
      </p>
    </div>
  {/if}

  <div class="crtw-w-full crtw-break-all">
    <p class="crtw-font-medium">Logged in address:</p>
    <p class="crtw-text-sm">
      {#await $userAddressStore then userAddress}
        {userAddress}
      {/await}
    </p>
  </div>

  {#if balance}
    <div class="crtw-w-full crtw-break-all">
      <p class="crtw-font-medium">{`${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`} available:</p>
      <p class="crtw-text-sm">{balance}</p>
    </div>
  {/if}
</div>
