<script lang="ts">
  import { get } from 'svelte/store'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import { fn, configStore } from '@stores/passport-store'
  import Container from '@components/container/Container.svelte'

  const config = get(configStore)
  const { logout, close } = get(fn)

  const executeLogout = async () => {
    logout()
    close()
  }
</script>

<ContentHeader
  text={config.content?.logout?.title || 'Sign out'}
  description={config.content?.logout?.description ||
    'This will sign you out and you will be asked to verify your identity next time you sign in.'}
/>

<Container>
  <ButtonBlack text="Sign out now" onClick={executeLogout} />
</Container>
