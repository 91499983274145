export const DEFAULT_TOKEN_ID = 0

export const TRUSTED_FORWARDER = '0xAebF6188EA36F166A15D9003a7F761122b695a70'.toLowerCase()

export const DEFAULT_PROGRESS_VIDEO = {
  src: 'https://customer-bmztknkgqgwr6a86.cloudflarestream.com/b6057185863fe1c1ca2875dbf11ea447/watch',
  embedded: true,
}

export const DEFAULT_LOADER_TITLE = 'We are processing your order..'

export const CREDENZA_SYMBOL_NAME = 'CRED'
