import SoldOut from '@images/sold_out.svg'

export const ERRORS = {
  titles: {
    DEFAULT_ERROR_TITLE: 'Something went wrong',
    TRANSACTION_REVERTED_TITLE: 'The transaction is reverted',
    SOLD_OUT_ERROR_TITLE: 'Sold out',
    INVALID_TOKEN_ERROR_TITLE: 'Invalid token ID',
  },
  desctiptions: {
    DEFAULT_ERROR_DESC: 'An unexpected error occured and we are unable to complete your order.',
    SOLD_OUT_ERROR_DESC:
      'We are unable to complete your order as the item that you are trying to add is currently sold out.',
    INVALID_TOKEN_ERROR_DESC: 'There is no token associated with provided ID.',
    HAS_MEMBERSHIP_ALREADY_ERROR_DESC: 'This membership is already assosiated with your account.',
  },
}

export const handleErrorData = (message: string) => {
  let res = {}
  if (message.includes('SOLD_OUT') || message.includes('Sold out'))
    res = {
      title: ERRORS.titles.SOLD_OUT_ERROR_TITLE,
      description: ERRORS.desctiptions.SOLD_OUT_ERROR_DESC,
      image: SoldOut,
    }
  if (message.includes('invalid token ID'))
    res = {
      title: ERRORS.titles.INVALID_TOKEN_ERROR_TITLE,
      description: ERRORS.desctiptions.INVALID_TOKEN_ERROR_DESC,
    }

  if (message.includes('ALREADY_HAS_MEMBERSHIP')) {
    res = {
      title: ERRORS.titles.TRANSACTION_REVERTED_TITLE,
      description: ERRORS.desctiptions.HAS_MEMBERSHIP_ALREADY_ERROR_DESC,
    }
  }

  return res
}
