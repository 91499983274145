<style lang="scss">
  :global(.cpnav svg:focus) {
    outline: 0;
  }
  .brand-credenza {
    background-color: #fff;
  }
</style>

<script lang="ts">
  import { get } from 'svelte/store'
  import { getIconAttrs } from '@lib/images/images'

  import MenuItem from '@nav/components/menu-item/MenuItem.svelte'
  import PassportIcon from '@nav/images/passport.svg'
  import ChevronUpIcon from '@nav/images/chevron-up.svg'
  import MinimizationToggler from '@nav/components/minimization-toggler/MinimizationToggler.svelte'

  import { NavDirections, Themes, NavMinimizationTogglerPositions } from '@src/Passport.enums'
  import type { TNavConfig } from '@src/Passport.types'
  import { configStore, isLoggedInStore, fn } from '@stores/passport-store'

  export let styles: { [key: string]: string }

  export let navConfig: TNavConfig

  const config = get(configStore)
  const { openUI } = get(fn)

  const cssVarStyles = Object.entries(styles)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')

  let isExpanded: boolean = false
  let isMinimized: boolean = navConfig.minimization?.enabled || false
  const navDirection = config.nav?.direction || NavDirections.TOP
  const theme = config.nav?.theme || Themes.WHITE

  isLoggedInStore.subscribe((isLoggedIn) => {
    if (!isLoggedIn) isExpanded = false
  })

  let chevronStyles = ''
  $: {
    chevronStyles = Object.entries({
      // safari doesn't respect transform as a direct attribute
      ...(isExpanded ? { transform: 'scale(1, -1)' } : {}),
      ...getIconAttrs(theme),
    })
      .map(([key, value]) => `${key}:${value}`)
      .join(';')
  }
</script>

<div class="crdz-ui" id="cpnav">
  <div
    class="cpnav crtw-fixed crtw-z-50 crtw-w-56 crtw-flex crtw-flex-col crtw-items-center crtw-justify-center crtw-bg-white crtw-text-gray-800
    crtw-font-[poppins] crtw-text-xs crtw-border crtw-border-gray-700 crtw-rounded crtw-cursor-pointer crtw-pr-1.5"
    class:crtw-bg-black={theme === Themes.BLACK}
    class:crtw-text-white={theme === Themes.BLACK}
    class:crtw-w-[70px]={isMinimized}
    class:crtw-pr-5={navConfig.minimization?.toggler?.enabled &&
      navConfig.minimization?.toggler?.position === NavMinimizationTogglerPositions.RIGHT}
    class:crtw-pl-5={navConfig.minimization?.toggler?.enabled &&
      navConfig.minimization?.toggler?.position !== NavMinimizationTogglerPositions.RIGHT}
    style={cssVarStyles}
  >
    <MinimizationToggler {theme} {navConfig} bind:isMinimized />
    {#if navDirection === NavDirections.TOP && isExpanded}
      {#await import('@nav/components/expanded-nav/ExpandedNav.svelte') then module}
        <svelte:component this={module.default} {isMinimized} {theme} />
      {/await}
    {/if}
    <MenuItem
      isMain={true}
      title={`${import.meta.env.PASSPORT_BRAND_NAV_NAME}`}
      subtitle={$isLoggedInStore ? undefined : import.meta.env.PASSPORT_BRAND_NAV_SUBTITLE || undefined}
      {isMinimized}
      {theme}
      onClick={() => ($isLoggedInStore ? (isExpanded = !isExpanded) : openUI())}
    >
      <div
        class={`crtw-flex crtw-justify-center crtw-items-center crtw-rounded-md brand-${
          import.meta.env.PASSPORT_BRAND_LABEL
        }`}
        slot="icon"
      >
        <img class="crtw-block crtw-max-w-full" src={PassportIcon} alt="" />
      </div>
      {#if $isLoggedInStore}
        <img src={ChevronUpIcon} alt="" style={chevronStyles} />
      {/if}
    </MenuItem>
    {#if navDirection === NavDirections.BOTTOM && isExpanded}
      {#await import('@nav/components/expanded-nav/ExpandedNav.svelte') then module}
        <svelte:component this={module.default} {isMinimized} {theme} />
      {/await}
    {/if}
  </div>
</div>
