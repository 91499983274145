import merge from 'lodash.merge'

import { config, membershipContracts, erc20CredTokens } from '@src/config'
import { getNetworkType, retrieveCredenzaAccess } from '@src/Passport.service'
import { contractsStore, configStore, isLoggedInStore } from '@stores/passport-store'
import { PassportEvents, dispatch } from '@lib/events/events'
import { createPayPeyArticleObserver } from '@lib/observers/ppa'

import type { Passport } from '@src/Passport'
import type { TPassportConstructor } from '@src/Passport.types'
import { get } from 'svelte/store'
import { initCredenzaSDK, sdk } from '@lib/oauth/oauth'

export async function configurePassport(this: Passport, opts: TPassportConstructor) {
  this.networkType = getNetworkType(opts.chainId)
  this.config = {
    ...merge(config[this.networkType], opts.config || {}),
    chainId: opts.chainId,
    clientId: opts.clientId,
    networkType: this.networkType,
  }
  this.chainId = this.config.chainId
  this.contracts = {
    erc20Cred: erc20CredTokens[this.config.chainId],
    membership: membershipContracts[this.config.chainId],
  }
  contractsStore.set(this.contracts)
  configStore.set(this.config)
}

export async function init(this: Passport) {
  try {
    await initCredenzaSDK()
    if (!sdk.getAccessToken()) return
    await retrieveCredenzaAccess()
    createPayPeyArticleObserver()
  } catch (_) {
    /** */
  }
  dispatch(PassportEvents.INIT)
  if (get(isLoggedInStore)) this.close()
}
