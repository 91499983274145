<style>
  .phone {
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>

<script lang="ts">
  export let isPhone = false
  export let isValid = true

  export let value: string | number | null = null
</script>

<input
  class="crtw-bg-white crtw-w-full crtw-h-[53px] crtw-text-xs crtw-text-[#121e2b] crtw-border crtw-border-[#d9d9d9] crtw-rounded crtw-px-2.5 crtw-py-0 crtw-placeholder-[#d9d9d9] focus:crtw-border-[#121e2b] focus:crtw-outline-none disabled:crtw-bg-[#d9d9d9]"
  class:phone={isPhone}
  class:crtw-border-[#990008]={!isValid}
  class:focus:crtw-border-[#990008]={!isValid}
  bind:value
  {...$$restProps}
/>
