<script lang="ts">
  import { get } from 'svelte/store'
  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import { fn, userStore, pageStore, forcedPageStore } from '@stores/passport-store'
  // import Resend from '@pages/confirm-account/resend/Resend.svelte'
  import { Pages } from '@src/Passport.enums'
  import ButtonLink from '@components/inputs/button-link/ButtonLink.svelte'
  import Label from '@components/inputs/input/Label.svelte'
  import Container from '@components/container/Container.svelte'
  import CodeInput from '@pages/confirm-account/code-input/CodeInput.svelte'

  const { confirmAccount, close, toastAlert } = get(fn)

  let confirmPhoneCode: number
  let confirmEmailCode: number
  let isLoading = false

  const confirm = async () => {
    isLoading = true
    try {
      const res = await confirmAccount({
        ...($userStore?.pending?.email ? { emailCode: String(confirmEmailCode) } : {}),
        ...($userStore?.pending?.phone ? { phoneCode: String(confirmPhoneCode) } : {}),
      })

      if (($userStore?.pending?.phone && !res.phone) || ($userStore?.pending?.email && !res.email))
        throw new Error('The code is invalid.')

      const page = get(pageStore)
      if (!page || page === Pages.CONFIRM_ACCOUNT || page === Pages.LOGOUT) close()
    } catch (err) {
      toastAlert(err?.message || err, 'failure')
    }
    isLoading = false
  }
</script>

<ContentHeader text="Before we start please verify your phone number" />

<Container>
  {#if $userStore?.pending?.phone}
    <Label for="code">Enter your code sent to {$userStore.pending?.phone}</Label>
    <CodeInput bind:value={confirmPhoneCode} placeholder="SMS Code" />
    <!-- <Resend value={$userStore?.pending?.phone} type="PHONE" /> -->
  {/if}
  {#if $userStore?.pending?.email}
    <Label for="code">Enter your code sent to {$userStore?.pending?.email}</Label>
    <CodeInput bind:value={confirmEmailCode} placeholder="Email Code" />
    <!-- <Resend value={$userStore?.pending?.email} type="EMAIL" /> -->
  {/if}
  <ButtonBlack
    text="Verify"
    onClick={confirm}
    disabled={isLoading ||
      ($userStore?.pending?.phone && String(confirmPhoneCode).length !== 6) ||
      ($userStore?.pending?.email && String(confirmEmailCode).length !== 6)}
  />
</Container>

<div class="crtw-mt-4">
  <ButtonLink
    text="Update your account details"
    onClick={() => {
      forcedPageStore.set(Pages.UPDATE_PROFILE)
    }}
  />
</div>
