<script lang="ts">
  import { get } from 'svelte/store'
  import CloseIcon from '@images/close-button.svg'
  import { fn, cloakStore, pageStore, forcedPage, pageOptsStore, ppaStore } from '@stores/passport-store'
  import { dispatch, PassportEvents } from '@lib/events/events'

  const handleCloseClick = () => {
    const { close } = get(fn)

    dispatch(PassportEvents.UI_CLOSED, {
      page: <string>(get(forcedPage) || get(pageStore)),
      pageOpts: get(pageOptsStore),
    })
    close()
  }
</script>

{#if !$cloakStore && !$ppaStore.active && !$ppaStore.loading}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="crtw-float-right crtw-cursor-pointer crtw-h-8 crtw-w-8 crtw-absolute crtw-top-0 crtw-right-0 crtw-z-1000001 crtw-m-2 crtw-bg-white crtw-rounded-full sm:crtw-m-4"
    on:click={handleCloseClick}
    role="button"
    tabindex={0}
  >
    <img src={CloseIcon} alt="close-icon" class="crtw-fill-red-500 crtw-w-8 crtw-h-8" />
  </div>
{/if}
