import { pageOptsStore } from '@stores/passport-store'
import { get } from 'svelte/store'

export const setVideoEnded = () => {
  pageOptsStore.set({
    ...get(pageOptsStore),
    isProgressFinished: false,
    isVideoEnded: true,
  })
}

export const waitVideoEnded = async () => {
  pageOptsStore.set({ ...get(pageOptsStore), isProgressFinished: true, isVideoEnded: false })

  return new Promise((resolve) => {
    const unsubscribe = pageOptsStore.subscribe((pageOpts) => {
      if (pageOpts.isVideoEnded) {
        pageOptsStore.set({ ...get(pageOptsStore), isProgressFinished: false, isVideoEnded: false })
        unsubscribe()
        resolve(null)
      }
    })
    setTimeout(() => {
      try {
        unsubscribe()
      } catch (err) {
        /**/
      }
      resolve(null)
    }, 60 * 1000)
  })
}
