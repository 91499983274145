<script lang="ts">
  import { get } from 'svelte/store'
  import { ethers } from '@credenza3/core-web-evm-ext'

  import { configStore, fn, userAddressStore, forcedPageStore, pageStore } from '@stores/passport-store'
  import { Pages } from '@src/Passport.enums'

  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import ButtonLink from '@components/inputs/button-link/ButtonLink.svelte'
  import UserInfo from '@components/user-info/UserInfo.svelte'
  import { getProfileLinks } from '@pages/profile/Profile.service'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'

  import { TProfileLinks } from '@pages/profile/Profile.types'
  import NewWindowIcon from '@images/new-window.svg'
  import GitbookIcon from '@images/gitbook-logo.svg'
  import AccountIcon from '@nav/images/account.svg'

  import { getGitbookAccessToken } from '@src/Passport.service'

  const { getCREDContract, checkMembership } = get(fn)

  let links: TProfileLinks = {} as TProfileLinks
  let balance: string | null = null
  let gitbookToken: string | null = null

  const getProfileInfo = async () => {
    const publicKey = await get(userAddressStore)
    links = getProfileLinks(publicKey)
    try {
      const { isMember } = await checkMembership(publicKey)
      if (isMember) gitbookToken = await getGitbookAccessToken()
    } catch (err) {
      gitbookToken = null
    }

    if ($configStore.buyTokens !== false) {
      try {
        const { contract, decimals, address: contractAddress } = await getCREDContract()
        const [balanceResult, symbol] = await Promise.all([
          contract.balanceOf(publicKey).then((weiBalance: bigint) => ethers.formatUnits(weiBalance, decimals)),
          contract.symbol(),
        ])
        balance = `${balanceResult} ${symbol}`
        analytics.track('cp_profile_balance_read', {
          chain: $configStore.chainId,
          contract: contractAddress,
          decimals,
          balance: balanceResult,
          symbol,
        })
      } catch (err) {
        balance = '-'
      }
    }
  }

  $: $configStore.chainId && getProfileInfo()
</script>

<ContentHeader text="Account Details" />
<div class="crtw-flex crtw-flex-col crtw-w-full crtw-mt-9 crtw-gap-9">
  <UserInfo {balance} />
  <div class="crtw-flex crtw-flex-col crtw-w-full crtw-items-center crtw-gap-2.5">
    {#if links?.passportLink}
      <a
        href={links.passportLink}
        target="_blank"
        title="View on Passport"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={AccountIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View on Passport</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if links?.scanLink && links?.scanIcon}
      <a
        href={links.scanLink}
        target="_blank"
        title="View in the block explorer"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={links.scanIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View in {links.scanName || 'Etherscan'}</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if links?.nftsLink && links?.nftsIcon}
      <a
        href={links.nftsLink}
        target="_blank"
        title="View assets"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={links.nftsIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>View assets on OpenSea</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
    {#if gitbookToken}
      <a
        href={`${$configStore.credenza.gitbookUrl}?jwt_token=${gitbookToken}`}
        target="_blank"
        title="GitBook docs"
        class="crtw-flex crtw-items-center crtw-justify-start crtw-w-full crtw-border crtw-border-gray-300 crtw-p-2.5 crtw-no-underline crtw-text-gray-600"
        rel="noreferrer"
      >
        <img class="crtw-h-7 crtw-mr-14" src={GitbookIcon} alt="" />
        <div class="crtw-flex crtw-w-full crtw-justify-between">
          <span>GitBook docs</span>
          <img src={NewWindowIcon} alt="" />
        </div>
      </a>
    {/if}
  </div>
  <div class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center crtw-gap-4">
    <ButtonLink text="Update your account details" onClick={() => forcedPageStore.set(Pages.UPDATE_PROFILE)} />
    <ButtonBlack text="Sign Out" onClick={() => pageStore.set(Pages.LOGOUT)} />
  </div>
</div>
