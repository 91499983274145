<script lang="ts">
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import type { Scanner } from '@credenza-web3/scanner'

  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import Loader from '@components/progress/Loader.svelte'
  import { pageStore, fn, configStore } from '@stores/passport-store'
  import { ScanTypes } from '@src/Passport.enums'
  import { once, PassportEvents } from '@lib/events/events'
  import {
    goToPayment,
    goToSendTokens,
    processAirDrop,
    processRequestLoyaltyPoints,
  } from '@pages/scanner/Scanner.service'

  const { close } = get(fn)
  let scanner: Scanner
  let isScannerLoaded = false

  const onCapture = async ({
    message,
    userAddress,
    scanType,
    chainId,
  }: {
    userAddress: string
    message: string
    scanType: string
    chainId: string
  }) => {
    if (chainId !== $configStore.chainId) return
    scanner.close()
    switch (scanType) {
      case ScanTypes.PASSPORT_ID_PASS:
      case ScanTypes.PASSPORT_ID: {
        const parsed = JSON.parse(message)
        goToSendTokens({ userAddress: parsed.userAddress || userAddress, amount: parsed?.amount })
        break
      }
      case ScanTypes.TOKENS_PURCHASE: {
        goToPayment(JSON.parse(message))
        break
      }
      case ScanTypes.AIR_DROP: {
        await processAirDrop(JSON.parse(message))
        break
      }
      case ScanTypes.REQUEST_LOYALTY_POINTS: {
        await processRequestLoyaltyPoints(JSON.parse(message))
        break
      }
      default: {
        close()
        pageStore.set(null)
      }
    }
  }

  onMount(async () => {
    const { Scanner } = await import('@credenza-web3/scanner')
    scanner = new Scanner({ target: '#cpuiScanner' })
    once(PassportEvents.UI_CLOSED, () => {
      scanner.close()
      pageStore.set(null)
    })
    await scanner.scan({ isCloseButton: false })
    isScannerLoaded = true
    scanner.on(Scanner.events.CAPTURE, onCapture)
    scanner.on(Scanner.events.ERROR, (err) => {
      const message = err?.message || err
      if (message.includes('Permission'))
        alert('In order to scan please allow the current page to access your camera and try again.')
    })
  })
</script>

<ContentHeader text="Scan QR code" />
<div class="crtw-w-full">
  {#if !isScannerLoaded}
    <div
      class="crtw-w-full crtw-h-64 crtw-rounded-[10px] crtw-flex crtw-items-center crtw-justify-center"
      style="background-color: rgb(238, 238, 238)"
    >
      <Loader />
    </div>
  {/if}
  <div
    id="cpuiScanner"
    class="crtw-w-full crtw-h-64 crtw-rounded-lg crtw-flex crtw-items-center crtw-justify-center"
    class:crtw-hidden={!isScannerLoaded}
    style="background-color: rgb(238, 238, 238)"
  />
</div>
