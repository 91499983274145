<script lang="ts">
  import { get } from 'svelte/store'
  import { ethers } from '@credenza3/core-web-evm-ext'
  import { onDestroy } from 'svelte'
  import MenuItem from '@nav/components/menu-item/MenuItem.svelte'
  import BuyTokenIcon from '@nav/images/buyToken.svg'
  import { Pages } from '@src/Passport.enums'
  import { on, PassportEvents } from '@lib/events/events'
  import type { TTheme } from '@src/Passport.types'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { Themes } from '@src/Passport.enums'
  import { fn, configStore, providerStore, userAddressStore } from '@stores/passport-store'
  import { getCryptoCurrencySymbol } from '@lib/strings/strings'

  export let theme: TTheme
  export let style: string
  export let isMinimized: boolean = false

  const { openUI, getCREDContract } = get(fn)

  let balance: string = ''
  let isLoading = false

  const getBalance = async () => {
    try {
      const { decimals, contract, address: contractAddress } = await getCREDContract()
      const [balanceResult, symbol] = await Promise.all([
        contract
          .balanceOf(await get(userAddressStore))
          .then((weiBalance: bigint) => ethers.formatUnits(weiBalance, decimals)),
        contract.symbol(),
      ])
      balance = `(${balanceResult} ${getCryptoCurrencySymbol(symbol)})`
      analytics.track('cp_nav_balance_read', {
        chain: $configStore.chainId,
        contract: contractAddress,
        decimals,
        balance: balanceResult,
        symbol,
      })
    } catch (e) {
      console.log(e)
      balance = ''
    }
  }

  const offRecheckBalanceListener = on(PassportEvents.RECHECK_BALANCE, async (txHashes: string[]) => {
    if (txHashes?.length) await Promise.all(txHashes.map((hash) => $providerStore.waitForTransaction(hash)))
    await getBalance()
  })

  onDestroy(offRecheckBalanceListener)

  $: $configStore.chainId && getBalance()
</script>

<MenuItem {isMinimized} {theme} title="Wallet" onClick={() => openUI(Pages.WALLET)} isDisabled={isLoading}>
  <img src={BuyTokenIcon} {style} slot="icon" alt="" />
  {#if !isLoading}
    <span class="cpnav-menu-item-balance crtw-text-[9px]" class:cpnav-menu-item-balance-white={theme === Themes.BLACK}
      >{balance}</span
    >
  {/if}
</MenuItem>
