<style>
  .brand-credenza {
    top: 8px;
    left: -1px;
  }
  .brand-nhl {
    top: 2px;
  }
  .brand-mlb.full {
    top: initial;
    bottom: 8px;
    left: 7px;
  }
</style>

<script lang="ts">
  import { isLoggedInStore } from '@stores/passport-store'
</script>

<div
  class={`crtw-w-1.5 crtw-h-1.5 crtw-rounded-[3px] crtw-absolute crtw-left-0 crtw-top-[8px] brand-${
    import.meta.env.PASSPORT_BRAND_LABEL
  }`}
  class:crtw-bg-green-500={$isLoggedInStore}
  class:crtw-bg-red-500={!$isLoggedInStore}
/>
