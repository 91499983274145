<script lang="ts">
  import { get } from 'svelte/store'
  import { generate } from 'lean-qr'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { toSvgSource } from 'lean-qr/extras/svg'
  import { configStore, providerStore, userAddressStore, accessTokenStore, fn } from '@stores/passport-store'
  import UserInfo from '@components/user-info/UserInfo.svelte'
  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import { PassportLocalStorage } from '@src/Passport.enums'

  import { ScanTypes } from '@src/Passport.enums'
  import ReloadIcon from '@images/reload.svg'
  import AppleWalletImg from '@images/apple_wallet.svg'

  const { toastAlert } = get(fn)

  let QrCodeSvg: string | null = null
  let message: string = 'Signing your ID...'

  $: if ($configStore.chainId) {
    QrCodeSvg = null
    generateCode()
  }

  const generateCode = async () => {
    try {
      const date = new Date().toISOString()
      const signer = await $providerStore.getSigner()
      const signature = await signer.signMessage(date)
      analytics.track('cp_passport_id', { date, address: await signer.getAddress() })
      const qrData = JSON.stringify({
        scanType: ScanTypes.PASSPORT_ID,
        date,
        chainId: $configStore.chainId,
        sig: signature,
      })
      const code = generate(qrData)
      QrCodeSvg = toSvgSource(code, { width: 250, height: 250 })

      try {
        localStorage.setItem(PassportLocalStorage.QR_CODE, qrData)
      } catch (err) {
        toastAlert('QR code caching failed. Please check your browser settings and enable local storage.')
      }
    } catch (err) {
      console.log(err)
      message = 'QR code generation failed'
    }
  }

  const getAppleWalletLink = async () => {
    const url = new URL(`${$configStore.credenza.apiUrl}/apple/pkpass/passportId/`)
    url.searchParams.append('chainId', $configStore.chainId)
    url.searchParams.append('address', await get(userAddressStore))
    url.searchParams.append('sessionToken', `Bearer ${get(accessTokenStore)}`)
    return url.toString()
  }
</script>

<ContentHeader text="show id" description="This information is used to verify your personal identity." />
<UserInfo />
<div class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center crtw-w-full">
  {#if QrCodeSvg}
    <div>{@html QrCodeSvg}</div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="crtw-flex crtw-items-center crtw-cursor-pointer crtw-text-xs crtw-mt-[-10px] crtw-gap-2.5 crtw-font-medium"
      on:click={generateCode}
      role="button"
      tabindex="0"
    >
      <img src={ReloadIcon} alt="reload-icon" class="crtw-w-4" />
      Refresh code
    </div>

    {#await getAppleWalletLink() then appleWalletUrl}
      <a href={appleWalletUrl} style="margin-top: 10px">
        <img src={AppleWalletImg} alt="Add to Apple Wallet" />
      </a>
    {/await}
  {:else}
    <div class="crtw-py-2.5 crtw-text-sm">{message}</div>
  {/if}
</div>
