<style lang="scss">
  .cpui-minimization-toggler-left {
    left: 0;
    border-right: 1px solid;
  }

  .cpui-minimization-toggler-right {
    right: 0;
    border-left: 1px solid;
  }
</style>

<script lang="ts">
  import { NavMinimizationTogglerPositions, Themes } from '@src/Passport.enums'
  import type { TNavConfig, TTheme } from '@src/Passport.types'

  export let theme: TTheme
  export let navConfig: TNavConfig
  export let isMinimized: boolean
</script>

{#if navConfig.minimization?.toggler?.enabled}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    role="button"
    tabindex={0}
    on:click={() => (isMinimized = !isMinimized)}
    class:border-white={theme === Themes.BLACK}
    class={`crtw-flex crtw-items-center crtw-justify-center crtw-w-4 crtw-absolute crtw-top-0 crtw-bottom-0 crtw-border-gray-700 cpui-minimization-toggler-${
      navConfig.minimization?.toggler?.position?.toLowerCase() ?? NavMinimizationTogglerPositions.LEFT.toLowerCase()
    }`}
  >
    {#if (isMinimized && navConfig.minimization?.toggler?.position === NavMinimizationTogglerPositions.RIGHT) || (!isMinimized && navConfig.minimization?.toggler?.position !== NavMinimizationTogglerPositions.RIGHT)}
      {'>'}
    {:else}
      {'<'}
    {/if}
  </div>
{/if}
