import { Chains } from '@src/Passport.enums'

export const CHAIN_INFO = {
  [Chains.POLYGON]: {
    name: 'Polygon',
    rpcUrl: 'https://polygon-bor.publicnode.com',
    wsRpcUrl: 'wss://polygon-bor.publicnode.com',
    scanUrl: 'https://polygonscan.com',
  },
  [Chains.POLYGON_MUMBAI]: {
    name: 'Mumbai',
    rpcUrl: 'https://polygon-mumbai-bor.publicnode.com',
    wsRpcUrl: 'wss://polygon-mumbai-bor.publicnode.com',
    scanUrl: 'https://mumbai.polygonscan.com',
  },
  [Chains.CHILIZ]: {
    name: 'Chiliz',
    rpcUrl: 'https://chiliz.publicnode.com/',
    wsRpcUrl: 'wss://chiliz.publicnode.com',
    scanUrl: 'https://scan.chiliz.com/',
  },
  [Chains.CHILIZ_SPICY]: {
    name: 'Spicy',
    rpcUrl: 'https://chiliz-spicy.publicnode.com/',
    wsRpcUrl: 'wss://chiliz-spicy.publicnode.com',
    scanUrl: 'https://spicy-explorer.chiliz.com/',
  },
} as const
