import { CHAIN_INFO } from '@src/Passport.chains'
import { Chains } from '@src/Passport.enums'

export const chainIdToHex = (chainId: string) => `0x${Number(chainId).toString(16)}`

export const mumbai = {
  chainId: chainIdToHex(Chains.POLYGON_MUMBAI),
  rpcUrl: CHAIN_INFO[Chains.POLYGON_MUMBAI].rpcUrl,
  displayName: CHAIN_INFO[Chains.POLYGON_MUMBAI].name,
  blockExplorer: CHAIN_INFO[Chains.POLYGON_MUMBAI].scanUrl,
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
}

export const polygon = {
  chainId: chainIdToHex(Chains.POLYGON),
  rpcUrl: CHAIN_INFO[Chains.POLYGON].rpcUrl,
  displayName: CHAIN_INFO[Chains.POLYGON].name,
  blockExplorer: CHAIN_INFO[Chains.POLYGON].scanUrl,
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
}

export const spicy = {
  chainId: chainIdToHex(Chains.CHILIZ_SPICY),
  rpcUrl: CHAIN_INFO[Chains.CHILIZ_SPICY].rpcUrl,
  displayName: CHAIN_INFO[Chains.CHILIZ_SPICY].name,
  blockExplorer: CHAIN_INFO[Chains.CHILIZ_SPICY].scanUrl,
  nativeCurrency: {
    name: 'CHZ',
    symbol: 'CHZ',
    decimals: 18,
  },
}

export const chiliz = {
  chainId: chainIdToHex(Chains.CHILIZ),
  rpcUrl: CHAIN_INFO[Chains.CHILIZ].rpcUrl,
  displayName: CHAIN_INFO[Chains.CHILIZ].name,
  blockExplorer: CHAIN_INFO[Chains.CHILIZ].scanUrl,
  nativeCurrency: {
    name: 'CHZ',
    symbol: 'CHZ',
    decimals: 18,
  },
}

export const getChainConfig = (chainId: string) => {
  switch (chainId) {
    case Chains.POLYGON_MUMBAI:
      return mumbai
    case Chains.CHILIZ_SPICY:
      return spicy
    case Chains.CHILIZ:
      return chiliz
    case Chains.POLYGON:
      return polygon
    default:
      throw new Error('Unsupported Chain Id: ' + chainId)
  }
}
