<script lang="ts">
  import Select from '@components/inputs/select/Select.svelte'
  import Input from '@components/inputs/input/Input.svelte'
  import { PHONE_PATTERN, validate } from '@lib/validation'
  import { countryCodes } from '@components/inputs/phone/PhoneInput.constants'

  export let phone: string
  export let isValid: boolean = true
  export let disabled: boolean = false

  let selectedCountryCode: string = countryCodes[0].value
  let number: number | null

  if (phone) {
    for (const countryCode of countryCodes) {
      if (phone.indexOf(countryCode.value) === 0) {
        number = parseInt(phone.slice(countryCode.value.length))
        selectedCountryCode = countryCode.value
        break
      }
    }
  }

  $: {
    isValid = !number || validate(String(number), PHONE_PATTERN)
    isValid && selectedCountryCode && number ? (phone = selectedCountryCode + String(number).trim()) : ''
  }
</script>

<div class="crtw-flex crtw-justify-center crtw-flex-row">
  <div class="crtw-w-40 crtw-mr-0.5">
    <Select options={countryCodes} simple bind:selected={selectedCountryCode} {disabled} />
  </div>
  <Input
    type="number"
    min="0"
    inputmode="numeric"
    pattern="[0-9]*"
    placeholder="Phone Number"
    bind:value={number}
    name="phone"
    {disabled}
  />
</div>
