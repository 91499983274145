export const PHONE_PATTERN = /^\d{7,14}$/
export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const validate = (value: string, pattern: RegExp = EMAIL_PATTERN) => pattern.test(value)

export const isUrlValid = (urlString: string | undefined | null) => {
  if (!urlString) return false
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}
