<script lang="ts">
  import { ethers } from '@credenza3/core-web-evm-ext'
  import { get } from 'svelte/store'
  import { type Scanner } from '@credenza-web3/scanner'

  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import ButtonBack from '@components/inputs/button-back/ButtonBack.svelte'

  import { configStore, fn, pageOptsStore, pageStore, userAddressStore, providerStore } from '@stores/passport-store'
  import { once, PassportEvents } from '@lib/events/events'
  import { Pages } from '@src/Passport.enums'
  import { getTxScanAddress } from '@lib/tx/tx'

  import ScanIcon from '@images/scanner.svg'
  import Input from '@components/inputs/input/Input.svelte'

  import { getCryptoCurrencySymbol } from '@lib/strings/strings'

  const config = get(configStore)
  const { sendTokens, getCREDContract, toastAlert } = get(fn)

  let userAddress = ''
  let recipientAddress = ''
  let amount: number | undefined

  let activeCurrency = ''

  let decimals = 18
  let symbol = '-'
  let balance = ''

  let nativeDecimals = 18
  let nativeSymbol = '-'
  let nativeBalance = ''

  let isScannerHidden = true
  let isLoading = false

  let contract: ethers.Contract
  let signer: ethers.JsonRpcSigner
  let scanner: Scanner

  const onScannerClicked = async () => {
    const { Scanner } = await import('@credenza-web3/scanner')
    scanner = new Scanner({ target: '#cpuiCredScanner' })
    once(PassportEvents.UI_CLOSED, scanner.close)
    await scanner.scan()
    scanner.on(
      Scanner.events.CAPTURE,
      ({ userAddress: recipientUserAddress, chainId }: { userAddress: string; chainId: string }) => {
        if (chainId !== config.chainId) return
        recipientAddress = recipientUserAddress
        scanner.close()
      },
    )
    scanner.on(Scanner.events.CANCEL, () => (isScannerHidden = true))
    scanner.on(Scanner.events.ERROR, (err) => {
      const message = err?.message || err
      if (message.includes('Permission'))
        alert('In order to scan please allow the current page to access your camera and try again.')
      isScannerHidden = true
    })
    isScannerHidden = false
  }

  const sendTokensToRecipient = async () => {
    const isTCRED = activeCurrency === symbol
    isLoading = true
    try {
      const weiBn = ethers.parseUnits(String(amount), isTCRED ? decimals : nativeDecimals)
      const sendParams = [recipientAddress, weiBn, ...(isTCRED ? [contract] : [])] as const
      const result = await sendTokens(...sendParams)
      toastAlert(
        `<a href="${getTxScanAddress(result.hash)}" target="_blank">Send ${
          import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS
        } TX</a> was created`,
      )
      recipientAddress = ''
      amount = undefined
      void result
        .wait()
        .then(() => {
          toastAlert(
            `<a href="${getTxScanAddress(result.hash)}" target="_blank">Send ${
              import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS
            } TX</a> was mined`,
          )
          return Promise.all([contract.balanceOf(userAddress), signer.provider.getBalance(userAddress)])
        })
        .then(([weiBalance, weiNativeBalance]: [bigint, bigint]) => {
          balance = ethers.formatUnits(weiBalance, decimals)
          nativeBalance = parseFloat(ethers.formatUnits(weiNativeBalance, nativeDecimals)).toFixed(4)
        })
        .catch(() => {})
    } catch (err) {
      /***/
    }
    isLoading = false
  }

  const init = async () => {
    const pageOpts = get(pageOptsStore)
    recipientAddress = pageOpts?.to || ''
    amount = pageOpts?.amount || undefined
    const { decimals: contractDecimals, contract: credContract } = await getCREDContract()
    decimals = contractDecimals
    contract = credContract
    const provider = get(providerStore)
    signer = await provider.getSigner()
    userAddress = await get(userAddressStore)
    const results = await Promise.all([
      contract.symbol(),
      contract.balanceOf(userAddress),
      provider.getBalance(userAddress),
      fetch('https://chainid.network/chains.json')
        .then((result) => result.json())
        .then((networks) =>
          networks.find((network: { chainId: number }) => String(network.chainId) === config.chainId),
        ),
    ])
    symbol = results[0]
    balance = ethers.formatUnits(results[1], decimals)
    nativeDecimals = results[3]?.nativeCurrency?.decimals ?? 18
    nativeBalance = parseFloat(ethers.formatUnits(results[2], nativeDecimals)).toFixed(4)
    nativeSymbol = results[3]?.nativeCurrency?.symbol ?? 'ETH'
    activeCurrency = symbol
  }

  $: $configStore.chainId && $providerStore && init()

  $: isRecipientAddressValid = !!ethers.isAddress(recipientAddress)

  $: isSubmitDisabled =
    isLoading ||
    !amount ||
    !isRecipientAddressValid ||
    (activeCurrency === symbol && amount > +balance) ||
    (activeCurrency === nativeSymbol && amount > +nativeBalance)
</script>

<ContentHeader
  text="Send {`${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`}"
  description={isScannerHidden ? `Send your ${getCryptoCurrencySymbol(symbol)}/${nativeSymbol}` : 'Scan Passport Id'}
/>
<div class="crtw-w-full crtw-p-0 crtw-relative">
  <div
    id="cpuiCredScanner"
    class="crtw-absolute crtw-top-0 crtw-bottom-0 crtw-left-0 crtw-right-0 crtw-z-10 crtw-border crtw-border-gray-300 crtw-rounded-lg crtw-backdrop-blur-lg"
    class:crtw-hidden={isScannerHidden}
    class:crtw-z-[-1]={isScannerHidden}
  ></div>
  <div>ChainId: <span class="crtw-font-bold">{config.chainId}</span></div>
  <div>
    Your balance: <span class="crtw-font-bold"
      >{nativeBalance} {nativeSymbol} / {balance} {getCryptoCurrencySymbol(symbol)}</span
    >
  </div>

  <div class="crtw-mb-2.5 crtw-flex crtw-items-start crtw-justify-between crtw-w-full">
    <div class="crtw-flex crtw-flex-col crtw-w-full">
      <Input name="recipientAddress" type="text" placeholder="Address 0x..." bind:value={recipientAddress} />
      {#if recipientAddress && !isRecipientAddressValid}
        <label
          class="crtw-text-[10px] crtw-text-warningTextColor cpui-send-tokens-validation-message"
          for="recipientAddress">Provided address is not valid.</label
        >
      {/if}
    </div>

    <div class="crtw-ml-2.5 crtw-cursor-pointer">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <img src={ScanIcon} on:click={onScannerClicked} width="53px" alt="" class="crtw-h-[53px]" />
    </div>
  </div>

  <div class="crtw-mb-2.5 crtw-flex crtw-items-start crtw-justify-between crtw-w-full">
    <Input
      type="number"
      min="0"
      step="0.001"
      placeholder={`${getCryptoCurrencySymbol(activeCurrency)} amount`}
      bind:value={amount}
    />
    <div class="crtw-ml-2.5 crtw-w-16 crtw-min-w-16">
      <ButtonBlack
        onClick={async () => (activeCurrency === symbol ? (amount = +balance) : (amount = +nativeBalance))}
        text={`MAX`}
        disabled={+balance <= 0}
      />
    </div>
    <div class="crtw-ml-2.5 crtw-w-16 crtw-min-w-16">
      <ButtonBlack
        onClick={async () => (activeCurrency === symbol ? (activeCurrency = nativeSymbol) : (activeCurrency = symbol))}
        text={getCryptoCurrencySymbol(activeCurrency)}
        disabled={!activeCurrency}
      />
    </div>
  </div>
  <ButtonBlack
    onClick={sendTokensToRecipient}
    text={`Send ${amount || ''} ${getCryptoCurrencySymbol(activeCurrency)}`}
    {isLoading}
    disabled={isSubmitDisabled}
  />
</div>
<div class="crtw-mt-0.5 crtw-mb-3 crtw-w-full">
  <ButtonBack onClick={() => pageStore.set(Pages.WALLET)} />
</div>
