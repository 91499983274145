/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import packageJson from '@root/package.json'
import { get } from 'svelte/store'
import { ethers } from '@credenza3/core-web-evm-ext'
import './tailwind.scss'
import { handleTransactionInQuery } from '@src/Passport.service'
import {
  Chains,
  NetworkTypes,
  Pages,
  TransactionTypes,
  Themes,
  NavDirections,
  NavMinimizationTogglerPositions,
  ScanTypes,
} from '@src/Passport.enums'
import { PassportEvents } from '@lib/events/events.enums'
import { initAnalytics } from '@lib/mixpanel/mixpanel'
import type { TPassportConfig, TPassportConstructor, TChainId, TCredenzaContracts, TUser } from '@src/Passport.types'
import {
  configStore,
  accessTokenStore,
  userStore,
  providerStore,
  isLoggedInStore,
  fn,
  cloakStore,
  ppaStore,
} from '@src/stores/passport-store'
import {
  TPassportClassExtension,
  passportAuth,
  passportUi,
  passportInit,
  passportBlockchain,
  passportCommon,
  passportUser,
  passportEvents,
} from '@src/passport/index'

export class Passport {
  static version = packageJson.version
  static chains = Chains
  static pages = Pages
  static paymentTypes = TransactionTypes
  static themes = Themes
  static navDirections = NavDirections
  static navMinimizationTogglerPositions = NavMinimizationTogglerPositions
  static scanTypes = ScanTypes
  static events = PassportEvents

  public accessToken: string | null = null
  public config: TPassportConfig
  public isLoggedIn = false
  public user: TUser | null = null
  public ethers: typeof ethers
  public provider: ethers.BrowserProvider
  public chainId: TChainId
  public contracts: TCredenzaContracts
  public networkType: NetworkTypes.MAINNET | NetworkTypes.TESTNET

  constructor(opts: TPassportConstructor) {
    this.destroy()
    if (!(<TChainId[]>Object.values(Chains)).includes(opts?.chainId)) {
      throw new Error('Unsupported ChainId. Check "Passport.chains" for available chains.')
    }
    this.ethers = ethers
    this.configurePassport(opts)
    initAnalytics(this.networkType)

    fn.set({
      // ui
      openUI: this.openUI,
      close: this.close,
      toastAlert: (props) => {
        if (this.networkType === NetworkTypes.MAINNET) return
        return this.toastAlert(props)
      },
      // auth
      logout: this.logout,
      login: this.login.bind(this),
      // user
      updateProfile: this.updateProfile,
      confirmAccount: this.confirmAccount,
      // blockchain
      getCREDContract: this.getCREDContract,
      checkMembership: this.checkMembership,
      sendTokens: this.sendTokens,
      // common
      requestAirDrop: this.requestAirDrop,
      requestLoyaltyPoints: this.requestLoyaltyPoints,
    })

    userStore.subscribe((usr) => (this.user = usr))
    isLoggedInStore.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn || false
      handleTransactionInQuery(this.isLoggedIn, this.openUI)
    })
    accessTokenStore.subscribe((token) => (this.accessToken = token))
    providerStore.subscribe((provider) => (this.provider = provider))
    cloakStore.subscribe((enabled) => {
      if (enabled && typeof window !== 'undefined') void this.openUI()
    })
    ppaStore.subscribe(({ loading, active }) => {
      if (!get(configStore).content?.cloak || get(cloakStore) || typeof window === 'undefined') return
      if (active && loading) {
        return void this.openUI(Pages.LOADER, { loaderText: 'Checking your access...' })
      }
      if (active && !loading) {
        return void this.openUI(Pages.ERROR, {
          title: 'Access denied',
          description: 'You need to buy the subscription to access this page',
          button: false,
        })
      }
      this.close()
    })
  }
}

Object.assign(
  Passport.prototype,
  passportAuth,
  passportUi,
  passportInit,
  passportBlockchain,
  passportCommon,
  passportUser,
  passportEvents,
)
export interface Passport extends TPassportClassExtension {}
