<script lang="ts">
  import IsLoggedInIndicator from '@nav/components/menu-item/isLoggedInIndicator/IsLoggedInIndicator.svelte'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { Themes } from '@src/Passport.enums'
  import type { TTheme } from '@src/Passport.types'
  import { isLoggedInStore } from '@stores/passport-store'

  export let title: string
  export let subtitle: string | undefined = undefined
  export let onClick: (...args: unknown[]) => unknown
  export let isDisabled: boolean = false
  export let isMinimized: boolean = false
  export let theme: TTheme
  export let isMain: boolean = false

  const handleClick = () => {
    if (isDisabled) return
    onClick()
    analytics.track('cp_nav_item_click', { title })
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="crtw-w-full crtw-flex crtw-flex-row crtw-items-center crtw-justify-start crtw-h-11 crtw-relative crtw-box-border crtw-group"
  class:crtw-justify-center={isMinimized}
  class:crtw-w-11={isMinimized}
  on:click={handleClick}
  role="button"
  tabindex={0}
>
  {#if isMinimized && $isLoggedInStore}
    <span
      class="crtw-flex crtw-items-center crtw-justify-center crtw-invisible crtw-bg-white crtw-text-black crtw-text-center crtw-absolute crtw-z-10
      crtw-top-0 crtw-bottom-0 crtw-left-0 crtw-right-0 crtw-text-[9px] crtw-rounded crtw-overflow-hidden group-hover:crtw-visible"
      class:crtw-bg-black={theme === Themes.BLACK}
      class:crtw-text-white={theme === Themes.BLACK}
    >
      {#if isMain}
        <slot />
      {:else}
        {title}
      {/if}
    </span>
  {/if}
  <div class="crtw-flex crtw-items-center crtw-relative crtw-h-full crtw-mx-1.5">
    {#if isMain}
      <IsLoggedInIndicator />
    {/if}
    <slot name="icon" />
  </div>
  {#if !isMinimized}
    <div
      class="crtw-flex crtw-flex-col crtw-flex-1 crtw-w-full crtw-ml-1.5 crtw-justify-center"
      class:crtw-text-white={isDisabled}
    >
      {#if subtitle}
        <div class="crtw-text-[8px]">{@html subtitle}</div>
      {/if}
      {@html title}
    </div>
    <slot />
  {/if}
</div>
