export const getQueryParameters = () => {
  return new URLSearchParams(window.location.search.replace('?', ''))
}

export const checkQueryParam = (param: string) => {
  const query = getQueryParameters()
  return query.get(param)
}

export const clearAllQueryParams = () => {
  const currentUrl = new URL(window.location.href)
  currentUrl.search = ''
  history.pushState({}, '', currentUrl.toString())
}
