<script lang="ts">
  import { get } from 'svelte/store'
  import { fn, forcedPage, pageStore } from '@stores/passport-store'
  import Loader from '@components/progress/Loader.svelte'
  import AuthButton from './AuthButton.svelte'
  import SocialAuthButton from './SocialAuthButton.svelte'
  import Container from '@components/container/Container.svelte'
  import { AUTH_PROVIDERS, OAUTH_LOGIN_TYPE, OAUTH_PASSWORDLESS_TYPES } from '@lib/oauth/oauth.constants'
  import { generateAuthTypeImageHtml, getAuthMethodsConfigs } from './authMethods.helper'

  export let isLoading: boolean

  const { login, close } = get(fn)

  const handleLogin = async ({
    provider,
    oauthType,
    passwordlessType,
  }: {
    provider: AUTH_PROVIDERS
    oauthType?: OAUTH_LOGIN_TYPE
    passwordlessType?: OAUTH_PASSWORDLESS_TYPES
  }) => {
    try {
      isLoading = true
      await login(provider, oauthType, passwordlessType)
      isLoading = false
      if (!$forcedPage && !$pageStore) close()
    } catch (err) {
      isLoading = false
    }
  }

  /**
   * Retrieves the available login methods based on the configuration settings.
   * @returns {Array} An array of objects representing the available login methods.
   */
  type AvailableLoginMethod = {
    provider: AUTH_PROVIDERS
    oauthType?: OAUTH_LOGIN_TYPE
    passwordlessType?: OAUTH_PASSWORDLESS_TYPES
    text?: string
  }

  const getAvailableLoginMethods = (): AvailableLoginMethod[] => {
    const methodConfigs = getAuthMethodsConfigs()
    const availableLoginMethods = Object.entries(methodConfigs)
      .filter(([_, config]) => (config.condition !== undefined ? config.condition : true))
      .map(([_, config]) => ({
        provider: AUTH_PROVIDERS.OAUTH,
        ...config,
      }))

    return availableLoginMethods
  }

  const loginMethods = getAvailableLoginMethods()
</script>

{#if !isLoading}
  <Container class="crtw-mb-16">
    {#each loginMethods.slice(0, 2) as method}
      <AuthButton handler={() => handleLogin(method)}>
        Continue with {method.text || method.oauthType}
      </AuthButton>
    {/each}

    {#if loginMethods.length > 2}
      <div class="crtw-flex crtw-mt-12 crtw-mb-7 crtw-items-center crtw-justify-center">
        <div class="crtw-border-b crtw-border-gray-300 crtw-w-[21%]" />
        <span class="crtw-text-gray-400 crtw-text-[11px] crtw-mx-[7px]">or</span>
        <div class="crtw-border-b crtw-border-gray-300 crtw-w-1/4" />
      </div>
    {/if}

    <div class="crtw-flex crtw-items-center crtw-justify-center crtw-gap-4">
      {#each loginMethods.slice(2) as method}
        <SocialAuthButton handler={() => handleLogin(method)}>
          {@html generateAuthTypeImageHtml(method.passwordlessType || method.oauthType || method.provider)}
        </SocialAuthButton>
      {/each}
    </div>
  </Container>
{:else}
  <div class="crtw-m-5">
    <Loader />
  </div>
{/if}
