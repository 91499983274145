<script lang="ts">
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { pageOptsStore, fn } from '@stores/passport-store'

  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import ErrorImage from '@images/error.svg'
  import { ERRORS, handleErrorData } from './error.helper'

  const handleCloseClick = () => get(fn).close()

  let errorData: {
    errorMessage?: string
    image?: string
    description?: string
    title?: string
    button?: boolean
  }
  const unsub = pageOptsStore.subscribe((data) => {
    if (data.errorMessage) errorData = handleErrorData(data.errorMessage)
    else if (data.title && data.description) errorData = data
  })

  onDestroy(unsub)
</script>

<div class="crtw-flex crtw-flex-col crtw-justify-center crtw-items-center">
  <div class="crtw-mb-6">
    {#if errorData?.image}
      <img class="crtw-w-[90px]" src={errorData.image} alt="error" />
    {:else}
      <img src={ErrorImage} alt="error" />
    {/if}
  </div>

  <div class="crtw-text-2xl crtw-uppercase crtw-text-black crtw-font-bold">
    {errorData?.title || ERRORS.titles.DEFAULT_ERROR_TITLE}
  </div>

  <div class="crtw-text-base crtw-text-black crtw-text-center crtw-mb-9">
    {errorData?.description || ERRORS.desctiptions.DEFAULT_ERROR_DESC}
  </div>

  {#if errorData?.button !== false}
    <ButtonBlack text="Back so site" onClick={handleCloseClick} />
  {/if}
</div>
