<script lang="ts">
  import { PassportLocalStorage } from '@src/Passport.enums'
  import { generate } from 'lean-qr'
  import { toSvgSource } from 'lean-qr/extras/svg'

  const cachedQr = localStorage.getItem(PassportLocalStorage.QR_CODE)
  let QrCodeSvg: string

  const showQr = () => {
    if (!cachedQr) return
    const qrData = JSON.parse(cachedQr)
    const code = generate(JSON.stringify(qrData))
    QrCodeSvg = toSvgSource(code, { width: 250, height: 250 })
  }

  const hideQr = () => (QrCodeSvg = '')
</script>

{#if cachedQr}
  <div class={$$props.class}>
    <span class="crtw-text-sm">
      Cached QR is available.
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span
        class="crtw-cursor-pointer crtw-text-warningTextColor"
        on:click={QrCodeSvg ? hideQr : showQr}
        role="button"
        tabindex="0"
      >
        {QrCodeSvg ? 'Hide QR!' : 'Show QR!'}
      </span>
    </span>

    {#if QrCodeSvg}
      <div>{@html QrCodeSvg}</div>
    {/if}
  </div>
{/if}
