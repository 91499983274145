<script lang="ts">
  import { get } from 'svelte/store'
  import { isLoggingInProcessStore, pageOptsStore } from '@stores/passport-store'
  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import AuthMethods from '@pages/login/auth-methods/AuthMethods.svelte'
  import { configStore } from '@stores/passport-store'
  import Loader from '@components/progress/Loader.svelte'
  import CachedQr from './cachedQr/CachedQr.svelte'
  import { onDestroy } from 'svelte'

  let config = get(configStore)
  let isLoading: boolean = false

  const loaderText: string = get(pageOptsStore)?.loaderText || ''

  const authTitle = config.content?.auth?.title ?? 'Let’s get started'
  const authDescription = config.content?.auth?.description ?? 'Confirm your account to connect.'

  const loginIndicatorSub = isLoggingInProcessStore.subscribe((val) => (isLoading = val))

  onDestroy(loginIndicatorSub)
</script>

{#if loaderText}
  <p class="crtw-mb-5">{loaderText}</p>
  <Loader />
{:else}
  <div class="crtw-flex crtw-flex-col crtw-gap-14 crtw-items-center crtw-w-full">
    <ContentHeader text={authTitle} description={authDescription} />
    <AuthMethods bind:isLoading />

    <CachedQr class="crtw-mt-4" />
  </div>
{/if}
