/* eslint-disable max-lines */
import { TUser } from '@src/Passport.types'
import { get } from 'svelte/store'
import {
  configStore,
  accessTokenStore,
  userAddressStore,
  userStore,
  isLoggedInStore,
  providerStore,
  isLoggingInProcessStore,
} from '@stores/passport-store'
import { TAirDrop } from '@pages/scanner/Scanner.types'
import { Chains, NetworkTypes, PassportLocalStorage } from '@src/Passport.enums'
import { analytics } from '@lib/mixpanel/mixpanel'
import { ethers } from '@credenza3/core-web-evm-ext'
import { clearAllQueryParams } from '@lib/queryString/queryString'
import { checkTransactionQuery, goToPayment } from '@pages/scanner/Scanner.service'
import { sdk } from '@lib/oauth/oauth'
import { getUser } from './passport/user'

export const getNetworkType = (chainId: string) => {
  switch (chainId) {
    case Chains.POLYGON:
    case Chains.CHILIZ:
      return NetworkTypes.MAINNET
    default:
      return NetworkTypes.TESTNET
  }
}

export const getTokensByUserAddress = async ({
  address,
  tokenType,
}: {
  address: string
  tokenType: 'nft' | 'token'
}) => {
  const accessToken = get(accessTokenStore)
  if (!accessToken) throw new Error("Passport access token doesn't exist.")
  const config = get(configStore)
  const result = await fetch(config.credenza.apiUrl + `/users/${tokenType}/${config.chainId}/${address}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  if (!result.ok) throw new Error(`${result.status} ${result.statusText}`)
  return await result.json()
}

export const getGitbookAccessToken = async (): Promise<string> => {
  const { chainId, credenza } = get(configStore)
  const accessToken = get(accessTokenStore)
  if (!accessToken) throw new Error("Passport access token doesn't exist.")
  const result = await fetch(credenza.apiUrl + `/auth/gitbookToken/${chainId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  if (!result.ok) throw new Error(`${result.status} ${result.statusText}`)
  const res = await result.json()
  return res.gitbookToken
}

export const requestAirDropToken = async ({ contractAddress, tokenId, amount, targetAddress }: TAirDrop) => {
  const config = get(configStore)

  const result = await fetch(`${config.credenza.apiUrl}/chains/${config.chainId}/${contractAddress}/tokens/airDrop`, {
    method: 'post',
    body: JSON.stringify({
      targetAddress,
      ...(tokenId ? { tokenId: Number(tokenId) } : {}),
      ...(amount ? { amount } : {}),
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${get(accessTokenStore)}`,
    },
  })
  if (!result.ok) throw new Error(`${result.status} ${result.statusText}`)

  return await result.json()
}

export const requestLoyaltyPoints = async (eventId: string, contractAddress: string) => {
  const config = get(configStore)

  const params = new URLSearchParams({
    eventId,
    contractAddress,
    address: await get(userAddressStore),
  })

  const result = await fetch(`${config.credenza.apiUrl}/chains/${config.chainId}/requestLoyaltyPoints?${params}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${get(accessTokenStore)}`,
    },
  })

  const json = await result.json()
  if (!result.ok) throw new Error(json.error)

  return json
}

const checkCachedQrCode = (currentAddress: string) => {
  const cachedQrData = localStorage.getItem(PassportLocalStorage.QR_CODE)
  if (!cachedQrData) return
  try {
    const { date, sig } = JSON.parse(cachedQrData)
    const userAddress = ethers.verifyMessage(date, sig)
    if (userAddress.toLowerCase() !== currentAddress.toLowerCase()) throw new Error('Address mismatch')
  } catch {
    localStorage.removeItem(PassportLocalStorage.QR_CODE)
  }
}

export const retrieveCredenzaAccess = async () => {
  try {
    isLoggingInProcessStore.set(true)
    const config = get(configStore)
    const provider = await sdk.evm.getEthersProvider()
    providerStore.set(provider)

    const currentUserAddress = await (await provider.getSigner()).getAddress()
    const accessToken = sdk.getAccessToken()
    accessTokenStore.set(accessToken)
    checkCachedQrCode(currentUserAddress)

    const user: TUser = await getUser()
    userStore.set(user)
    isLoggedInStore.set(true)
    isLoggingInProcessStore.set(false)

    analytics.identify(user.id)
    analytics.people.set({
      $email: user.email,
      $phone: user.phone,
      $name: user.name,
      chain: config.chainId,
      provider,
      address: currentUserAddress,
      location: window.location.href,
      role: user.role || 'user',
    })
    analytics.track('cp_init', {
      $email: user.email,
      $phone: user.phone,
      chain: config.chainId,
      provider,
      address: currentUserAddress,
      location: window.location.href,
    })
  } catch (err) {
    localStorage.removeItem(PassportLocalStorage.ACCESS_TOKEN)
    isLoggedInStore.set(false)
    isLoggingInProcessStore.set(false)
    userStore.set(null)
    accessTokenStore.set(null)
    console.error('Cannot init credenza user', err)
  }
}

export const handleTransactionInQuery = (isLoggedIn: boolean, openUI: () => void): void => {
  const params = Object.fromEntries(new URLSearchParams(location.search))
  const hasTransaction = checkTransactionQuery(params)
  if (!hasTransaction) return

  // if we have transaction, but we are not logged in
  if (!isLoggedIn) return void openUI()

  goToPayment({
    title: params.title,
    typeId: +params.typeId,
    amount: +params.amount,
    tokenId: params.tokenId,
    contractAddress: params.contractAddress,
  })
  void openUI()
  clearAllQueryParams()
}
