<script lang="ts">
  export let handler: () => void
</script>

<button
  class="crtw-w-full crtw-text-center crtw-h-11 crtw-font-medium
   crtw-rounded-sm crtw-text-[13px] sm:crtw-text-sm crtw-items-center crtw-bg-black
crtw-text-white crtw-mt-[5px] crtw-justify-center crtw-flex"
  on:click={handler}
>
  <slot />
</button>
