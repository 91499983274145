<script lang="ts">
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { configStore, pageOptsStore } from '@stores/passport-store'
  import Progress from '@components/progress/Progress.svelte'
  import { DEFAULT_LOADER_TITLE, DEFAULT_PROGRESS_VIDEO } from '@src/Passport.constants'
  import { isUrlValid } from '@lib/validation'
  import { setVideoEnded } from '@components/progress/ProgressVideo.service'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'

  const text: string = get(pageOptsStore)?.loaderText || DEFAULT_LOADER_TITLE
  const list = get(pageOptsStore)?.progressVideos ||
    get(configStore)?.content?.progressVideos || [DEFAULT_PROGRESS_VIDEO]

  let selected: {
    src: string
    embedded?: boolean
  } | null = null

  let activeStep = 0
  let totalSteps = 10
  const initProgress = () => {
    setInterval(() => {
      activeStep = activeStep >= totalSteps ? 0 : activeStep + 1
    }, 100)
  }

  onMount(() => {
    if (!list.length) return initProgress()
    const randomIndex = Math.floor(Math.random() * list.length)
    selected = isUrlValid(list[randomIndex].src) ? list[randomIndex] : [DEFAULT_PROGRESS_VIDEO]
  })
</script>

<div class="crtw-w-full">
  {#if text}
    <div class="crtw-text-2xl crtw-font-bold crtw-font-['poppins'] crtw-mb-4">{text}</div>
  {/if}
  {#if !selected}
    <Progress step={activeStep} {totalSteps} />
  {:else if selected.embedded}
    <iframe
      id="videoProgressIframe"
      title="progress-video"
      frameborder="0"
      scrolling="no"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      allowfullscreen
      width="100%"
      style="min-height: 200px"
      src={`${selected.src}?autoplay=1&mute=1`}
    ></iframe>
  {:else if selected.src}
    <video width="100%" controls autoplay muted>
      <source src={selected.src} />
      Your browser does not support the video tag.
    </video>
  {/if}
</div>

{#if $pageOptsStore.isProgressFinished}
  <div class="crtw-pt-4 crtw-w-full">
    <ButtonBlack onClick={setVideoEnded} text="Skip video" />
  </div>
{/if}
