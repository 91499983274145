<script lang="ts">
  import { onMount } from 'svelte'
  import { dispatch, PassportEvents } from '@lib/events/events'

  export let doPayment: (stripeTokenId: string) => Promise<void>
  export let stripe: Window['Stripe']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export let stripeElements: any
  export let priceCents: number
  export let isLoading: boolean

  onMount(async () => {
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Apple/Google payment',
        amount: priceCents,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })
    const canMakePayment = await paymentRequest.canMakePayment()
    if (!canMakePayment) return

    const walletPayElement = document.getElementById('ctui-wallet-pay')
    if (walletPayElement) walletPayElement.style.display = 'crtw-block'

    const prButton = stripeElements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          height: '48px',
        },
      },
    })
    prButton.mount('#ctui-payment-request-button')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentRequest.on('token', async (event: any) => {
      isLoading = true
      try {
        await doPayment(event.token.id)
        await event.complete('success')
      } catch (err) {
        await event.complete('error')
        dispatch(PassportEvents.ERROR, { error: err })
      }
      isLoading = false
    })
  })
</script>

<div id="ctui-wallet-pay" class="crtw-w-full crtw-my-4" style="dispay: none;">
  <div id="ctui-payment-request-button"></div>
</div>
