<script lang="ts">
  export let selected: string | undefined = undefined
  export let label: string | undefined = undefined

  let isInputShown = false
  let active = selected

  const selectItems = [
    { value: '10', name: '$10' },
    { value: '25', name: '$25' },
    { value: '50', name: '$50' },
    { value: 'CUSTOM', name: 'Custom' },
  ]
  const handleChangeButton = (value: string) => {
    active = value
    if (value === 'CUSTOM') {
      isInputShown = true
      selected = ''
      return
    }
    isInputShown = false
    selected = value
  }
  const handleChangeInput = (e: Event) => {
    const target = e.target as HTMLSelectElement | HTMLInputElement
    target.value = target.value.replace(/[,.-]/g, '')
    if (+target.value > 100) {
      selected = '100'
      return
    }
    selected = target.value
  }
</script>

<div>
  <div class="crtw-flex crtw-flex-col">
    {label}
    <div class="crtw-inline-flex crtw-w-full" role="group">
      {#each selectItems as item}
        <button
          on:click={() => handleChangeButton(item.value)}
          class="crtw-w-full crtw-h-[30px] crtw-text-sm crtw-border hover:crtw-bg-gray-100 hover:crtw-text-blue-800 first:crtw-rounded-l last:crtw-rounded-r"
          class:crtw-border-blue-800={item.value === active}
          class:crtw-z-10={item.value === active}
        >
          {item.name}
        </button>
      {/each}
    </div>
    {#if isInputShown}
      <input
        class="crtw-appearance-none focus:crtw-outline-0 crtw-w-full crtw-h-9 crtw-mt-4 crtw-px-5 crtw-text-base crtw-border crtw-border-gray-300 crtw-rounded focus:crtw-border-blue-800 hover:crtw-bg-gray-100"
        type="number"
        min="1"
        max="100"
        bind:value={selected}
        on:input={handleChangeInput}
      />
    {/if}
  </div>
</div>
