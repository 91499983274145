<style lang="scss">
  .simple {
    background-image: none;
    padding: 0 10px;
    text-align: center;

    &:focus {
      background-image: none;
    }
  }
</style>

<script lang="ts">
  export let options: { value: string; name?: string }[]
  export let placeholder: string | undefined = undefined
  export let label: string | undefined = undefined
  export let selected: string | undefined = undefined
  export let onChange: undefined | ((...args: unknown[]) => unknown) = undefined
  export let simple: boolean = false
  export let disabled: boolean = false

  const handleChange = (e: Event) => {
    const target = e.target as HTMLSelectElement
    if (selected !== undefined) selected = target.value
    if (onChange) onChange(e)
  }
</script>

<div>
  {#if label}
    <label class="crtw-font-medium" for="credenzaSelect">{label}</label>
  {/if}
  <select
    class="crtw-w-full crtw-h-[53px] crtw-px-5 crtw-appearance-none crtw-text-base crtw-rounded crtw-border crtw-border-gray-300
   crtw-cursor-pointer crtw-text-gray-500 focus:crtw-outline-none focus:crtw-border-blue-900 focus:crtw-text-blue-900
    {simple ? 'simple' : ''}"
    name="credenzaSelect"
    on:change={handleChange}
    {disabled}
  >
    {#if placeholder}
      <option disabled={true} selected={!selected} hidden={true}>{placeholder}</option>
    {/if}
    {#each options as item}
      <option value={item.value} selected={selected === item.value}> {item.name ?? item.value} </option>
    {/each}
  </select>
</div>
