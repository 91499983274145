import { writable, derived } from 'svelte/store'
import { Pages } from '@src/Passport.enums'
import type { TPage, TPassportConfig, TCredenzaContracts, TUser, TFnWithoutPassportContext } from '@src/Passport.types'
import type { Passport } from '@src/Passport'
import { ethers } from '@credenza3/core-web-evm-ext'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pageOptsStore = writable<any>({})
export const pageStore = writable<TPage | null>(null)
export const configStore = writable<TPassportConfig>()
export const accessTokenStore = writable<string | null>()
export const userStore = writable<TUser | null>(null)
export const providerStore = writable<ethers.BrowserProvider>()
export const contractsStore = writable<TCredenzaContracts>()
export const isLoggedInStore = writable<boolean>()
export const isLoggingInProcessStore = writable<boolean>()
export const fn = writable<{
  openUI: Passport['openUI']
  close: Passport['close']
  toastAlert: Passport['toastAlert']
  logout: Passport['logout']
  login: TFnWithoutPassportContext<Passport['login']>
  updateProfile: Passport['updateProfile']
  confirmAccount: Passport['confirmAccount']
  sendTokens: Passport['sendTokens']
  getCREDContract: Passport['getCREDContract']
  checkMembership: Passport['checkMembership']
  requestAirDrop: Passport['requestAirDrop']
  requestLoyaltyPoints: Passport['requestLoyaltyPoints']
}>()

export const userAddressStore = derived(providerStore, async ($providerStore) => {
  try {
    if (!$providerStore) return ''

    const signer = await $providerStore.getSigner()
    const address = await signer.getAddress()
    return address.toLowerCase()
  } catch (err) {
    return ''
  }
})

export const forcedPageStore = writable<TPage | null>(null)
export const forcedPage = derived(
  [forcedPageStore, configStore, userStore, isLoggedInStore],
  ([$forcedPageStore, $configStore, $userStore, $isLoggedInStore]) => {
    // any forced page
    if ($forcedPageStore) {
      return $forcedPageStore
      // not logged in
    } else if (!$isLoggedInStore) {
      return Pages.LOGIN
      // extended reg
    } else if (
      $configStore.auth?.extendedRegistration &&
      (!$userStore?.name ||
        (!$userStore?.email && !$userStore?.pending?.email && !$userStore?.phone && !$userStore?.pending?.phone))
    ) {
      return Pages.UPDATE_PROFILE
      // unconfirmed account
    } else if ($userStore?.pending?.email || $userStore?.pending?.phone) {
      return Pages.CONFIRM_ACCOUNT
    }

    return null
  },
)

export const cloakStore = derived(
  [configStore, isLoggedInStore],
  ([$configStore, $isLoggedInStore]) => !$isLoggedInStore && !!$configStore.content?.cloak,
)

export const ppaStore = writable<{ loading: boolean; active: boolean }>({ loading: false, active: false })
