<script lang="ts">
  import { get } from 'svelte/store'
  import ContentHeader from '@components/content-header/ContentHeader.svelte'
  import AmountSelect from '@pages/wallet/amount-select/AmountSelect.svelte'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'

  import { pageStore, userAddressStore, fn, providerStore, configStore } from '@stores/passport-store'
  import { Pages } from '@src/Passport.enums'

  const { getCREDContract } = get(fn)

  let balance: number = 0

  const getBalance = async () => {
    try {
      const { contract } = await getCREDContract()
      balance = Number(await contract.balanceOf(await get(userAddressStore)))
    } catch {
      balance = 0
    }
  }

  $: $configStore.chainId && $providerStore && getBalance()
</script>

<div class="crtw-w-full crtw-px-2.5">
  <ContentHeader
    text="buy {`${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`}"
    description={`Buy/Send ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS} for purchases throughout this website.`}
  />
  <AmountSelect />
  <div class="crtw-m-3.5" />
  <ButtonBlack
    onClick={() => pageStore.set(Pages.SEND_TOKENS)}
    text="Send {`${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`}"
    disabled={!balance}
  />
</div>
