<script lang="ts">
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import { pageStore, pageOptsStore, contractsStore } from '@src/stores/passport-store'
  import { Pages } from '@src/Passport.enums'
  import ButtonGroup from '@pages/wallet/amount-select/ButtonGroup.svelte'

  let selected: string = '10'

  const goToPayment = () => {
    pageOptsStore.set({
      subtitle: `Purchase ${import.meta.env.PASSPORT_BRAND_STORED_VALUE_ALIAS}`,
      payments: {
        credenzaStoredValue: {
          disabled: true,
        },
      },
      tokens: [
        {
          contractAddress: $contractsStore.erc20Cred.address,
          amount: +selected,
        },
      ],
    })
    pageStore.set(Pages.PAYMENT)
  }
</script>

<ButtonGroup label="Select amount" bind:selected />
<div class="crtw-h-4" />
<ButtonBlack text="Confirm purchase" disabled={!selected} onClick={goToPayment} />
