export enum PassportEvents {
  ERROR = 'ERROR',
  INIT = 'INIT',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  PAYMENT = 'PAYMENT',
  SEND_TOKENS = 'SEND_TOKENS',
  SEND_NFT = 'SEND_NFT',
  RECHECK_BALANCE = 'RECHECK_BALANCE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED',
  TRANSACTION = 'TRANSACTION',
  RECEIPT = 'RECEIPT',
  UI_CLOSED = 'UI_CLOSED',
  REQUEST_AIRDROP = 'REQUEST_AIRDROP',
  REQUEST_LOYALTY_POINTS = 'REQUEST_LOYALTY_POINTS',
}
