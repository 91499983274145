import { dispatch, PassportEvents } from '@lib/events/events'
import { userStore } from '@stores/passport-store'

import type { TLoginOpts, TUser, TUserSocialLink } from '@src/Passport.types'
import { sdk } from '@lib/oauth/oauth'

export async function updateProfile(profileDataOpts: TLoginOpts & TUserSocialLink = {}) {
  try {
    if (!Object.keys(profileDataOpts).length) return

    const updatePromises = []
    if (profileDataOpts.name) updatePromises.push(sdk.account.updateProfile({ name: profileDataOpts.name }))
    if (profileDataOpts.email) updatePromises.push(sdk.account.changeEmail(profileDataOpts.email))
    if (profileDataOpts.phone) updatePromises.push(sdk.account.changePhone(profileDataOpts.phone))

    const res = await Promise.all(updatePromises)
    for (const item of res) {
      if (item.errors?.length) throw new Error(item.errors[0].error)
    }

    const user = await getUser()
    userStore.set(user)
    dispatch(PassportEvents.UPDATE_PROFILE, user)
    return user
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: err })
    throw err
  }
}

export async function confirmAccount(codes: { phoneCode?: string; emailCode?: string }) {
  try {
    const verifyPromises = []
    if (codes.phoneCode) verifyPromises.push(sdk.account.verifyCode(codes.phoneCode))
    if (codes.emailCode) verifyPromises.push(sdk.account.verifyCode(codes.emailCode))

    await Promise.all(verifyPromises)
    const user = await getUser()
    userStore.set(user)
    dispatch(PassportEvents.ACCOUNT_CONFIRMED, {
      ...(user?.name ? { name: user.name } : {}),
      ...(user?.email ? { email: user.email } : {}),
      ...(user?.phone ? { phone: user.phone } : {}),
    })
    return user
  } catch (err) {
    dispatch(PassportEvents.ERROR, { error: err })
    throw err
  }
}

const getPendingContacts = async () => {
  const pendingUserContracts: { [key: string]: string }[] = await sdk.account.pendingVerificationContacts()

  return pendingUserContracts.reduce((result, item) => {
    if (item.phone) result.phone = item.phone
    if (item.email) result.email = item.email
    return result
  }, {})
}

export const getUser = async (): Promise<TUser> => {
  return {
    ...(await sdk.account.info()),
    pending: {
      ...(await getPendingContacts()),
    },
  }
}
