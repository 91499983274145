<script lang="ts">
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import ButtonBack from '@components/inputs/button-back/ButtonBack.svelte'
  import { validate } from '@lib/validation'
  import PhoneInput from '@components/inputs/phone/PhoneInput.svelte'
  import { fn, userStore, pageStore, forcedPageStore, configStore } from '@stores/passport-store'
  import { Pages } from '@src/Passport.enums'
  import Label from '@components/inputs/input/Label.svelte'
  import Input from '@components/inputs/input/Input.svelte'
  import Container from '@components/container/Container.svelte'
  import { OAUTH_LOGIN_TYPE } from '@lib/oauth/oauth.constants'
  import { TUser } from '@src/Passport.types'
  import { toastAlert } from '@src/passport/ui'

  const { updateProfile, close } = get(fn)

  let email: string
  let name: string
  let phone: string

  let currentUser: TUser

  let isEmailChangeDisabled: boolean
  let isPhoneChangeDisabled: boolean

  const unsubscribeUserStore = userStore.subscribe((user) => {
    if (!user) {
      forcedPageStore.set(null)
      close()
      return
    }

    name = user.name || ''
    email = user.email || ''
    phone = user.phone || ''
    currentUser = user

    const isSocialLoginType =
      user.OAUTH_LOGIN_TYPE === OAUTH_LOGIN_TYPE.GOOGLE || user.OAUTH_LOGIN_TYPE === OAUTH_LOGIN_TYPE.TICKETMASTER
    isEmailChangeDisabled = !!user.pending?.email || isSocialLoginType
    isPhoneChangeDisabled = !!user.pending?.phone || isSocialLoginType
  })

  let isLoading = false
  let isEmailValid: boolean = false
  let isEmailInputValid: boolean = true
  let isNameValid: boolean = true

  const executeProfileUpdate = async () => {
    isLoading = true
    try {
      const newData = {
        ...(currentUser.email !== email ? { email } : {}),
        ...(currentUser.name !== name ? { name } : {}),
        ...(currentUser.phone !== phone ? { phone } : {}),
      }
      const updatedUser = await updateProfile(newData)
      forcedPageStore.set(null)

      toastAlert('Account has been updated successfully', 'success')
      const page = get(pageStore)
      if (!updatedUser?.pending?.phone && !updatedUser?.pending?.email && (!page || page === Pages.UPDATE_PROFILE))
        close()
    } catch (err) {
      toastAlert(err?.message || 'Error while updating your account details.', 'failure')
    }
    isLoading = false
  }

  $: {
    isEmailValid = validate(email)
    isEmailInputValid = !email || isEmailValid

    $configStore.auth?.extendedRegistration && (isNameValid = !!name)
  }

  onDestroy(unsubscribeUserStore)
</script>

<Container class="crtw-flex crtw-justify-center crtw-flex-col crtw-gap-2.5">
  <div class="crtw-flex crtw-flex-col">
    <Label for="NameInput"
      >First and last name
      {#if $configStore.auth?.extendedRegistration}
        <span class="crtw-text-red-500">*</span>
      {/if}
    </Label>
    <Input
      id="NameInput"
      type="text"
      placeholder="First and last name"
      bind:value={name}
      name="name"
      required={$configStore.auth?.extendedRegistration}
    />
  </div>

  <div class="crtw-flex crtw-flex-col">
    <Label for="EmailInput">Email address</Label>
    <Input
      id="EmailInput"
      type="email"
      bind:isValid={isEmailInputValid}
      placeholder="Email address"
      bind:value={email}
      name="email"
      disabled={isEmailChangeDisabled}
    />
  </div>

  <div class="crtw-flex crtw-flex-col">
    <Label for="PhoneInput">Phone number</Label>
    <PhoneInput bind:phone disabled={isPhoneChangeDisabled} />
  </div>

  <ButtonBlack
    text="Update profile"
    onClick={executeProfileUpdate}
    disabled={isLoading || !isEmailInputValid || !isNameValid}
  />

  {#if $forcedPageStore === Pages.UPDATE_PROFILE}
    <div class="crtw-text-left crtw-mt-2.5 crtw-w-full">
      <ButtonBack onClick={() => forcedPageStore.set(null)} />
    </div>
  {/if}
</Container>
