<script lang="ts">
  export let onClick: (...args: unknown[]) => unknown
  export let icon: string | undefined = undefined
  export let text: string

  export let styles: { [key: string]: string } = {}

  const cssVarStyles = Object.entries(styles)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')
</script>

<button
  class="crtw-bg-white crtw-border-0 crtw-my-0.5 crtw-px-0 crtw-text-black crtw-text-left crtw-flex crtw-items-center crtw-cursor-pointer crtw-font-['poppins'] crtw-text-[13px] crtw-underline"
  on:click={onClick}
  style={cssVarStyles}
>
  {#if icon}
    <img src={icon} alt="" class="crtw-mr-2 crtw-h-3" />
  {/if}
  {text}
</button>
