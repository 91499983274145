import { CREDENZA_SYMBOL_NAME } from '@src/Passport.constants'

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export const getCryptoCurrencySymbol = (symbol: string) => {
  if (symbol.toLowerCase() === CREDENZA_SYMBOL_NAME.toLowerCase()) return `${import.meta.env.PASSPORT_BRAND_CRED_ALIAS}`
  return symbol
}
