import { ENVS, connect, disconnect, request } from '@credenza-web3/async-client'
import { NetworkTypes } from '@src/Passport.enums'
import { accessTokenStore, configStore } from '@stores/passport-store'
import { get } from 'svelte/store'

const connectWsClient = async () => {
  try {
    const networkType = get(configStore).networkType
    await connect({
      accessToken: `Bearer ${get(accessTokenStore)}`,
      env: networkType === NetworkTypes.TESTNET ? ENVS.TEST : ENVS.MAIN,
      debug: networkType === NetworkTypes.TESTNET,
    })
  } catch (err) {
    console.error(err)
  }
}

export const requestWs = async <T>(channel: string, payload: unknown) => {
  try {
    await connectWsClient()
    const { payload: response } = await request({ channel, payload })
    return response as T
  } catch (err) {
    throw new Error(err.error?.message || 'Unknown error occurred. Please try again.')
  } finally {
    await disconnect()
  }
}
