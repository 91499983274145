<script lang="ts">
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { stripeStyles } from '@pages/payment/stripe/Stripe.styles'
  import ButtonBlack from '@components/inputs/button-black/ButtonBlack.svelte'
  import { dispatch, PassportEvents } from '@lib/events/events'
  import { TransactionTypes } from '@src/Passport.enums'
  import WalletPay from '@pages/payment/stripe/wallet-pay/WalletPay.svelte'
  import { loadScript } from '@lib/scripts/scripts'
  import { configStore, pageOptsStore, pageStore, userStore, userAddressStore, fn } from '@src/stores/passport-store'
  import { Pages } from '@src/Passport.enums'
  import { analytics } from '@lib/mixpanel/mixpanel'
  import { waitVideoEnded } from '@components/progress/ProgressVideo.service'

  export let priceCents: number
  export let isLoading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export let purchaseConfig: any
  export let isPriceLoading: boolean

  const { toastAlert } = get(fn)
  const config = get(configStore)

  let Stripe: Window['Stripe']
  let stripe: Window['Stripe']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let stripeElements: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let cardElement: any
  let isButtonActive: boolean

  let isEmailValid: boolean = true

  let isCardFocused = false
  onMount(async () => {
    try {
      if (!Stripe) {
        if (!window.Stripe) {
          await loadScript('https://js.stripe.com/v3/')
          if (!window.Stripe) throw {}
        }
        Stripe = window.Stripe
      }
    } catch (err) {
      return console.log('Please include stripe to use payments.')
    }

    stripe = Stripe(config.stripe.pk)
    stripeElements = stripe.elements()
    cardElement = stripeElements.create('card', { style: stripeStyles })
    cardElement.mount('#cpui-card-element')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardElement.on('change', (event: any) => {
      isButtonActive = event.complete && !event.error
    })
    cardElement.on('focus', () => (isCardFocused = true))
    cardElement.on('blur', () => (isCardFocused = false))
  })

  const handleSubmit = async () => {
    try {
      if (!isButtonActive) return
      isLoading = true
      const result = await stripe.createToken(cardElement)
      if (result.error) throw result.error
      await doPayment(result.token.id)
    } catch (err) {
      dispatch(PassportEvents.ERROR, { error: err })
    }
    isLoading = false
  }

  const doPayment = async (tokenId: string) => {
    try {
      const { processPayment } = await import('@pages/payment/stripe/Stripe.service')
      const { tokens, memberships } = await processPayment(tokenId)
      const txList = [...tokens, ...memberships].filter((item) => !!item.tx?.hash).map((item) => item.tx?.hash)
      dispatch(PassportEvents.PAYMENT, { type: TransactionTypes.STRIPE, results: { tokens, memberships } })
      toastAlert('Your payment was successful')
      dispatch(PassportEvents.RECHECK_BALANCE, txList)

      const user = get(userStore)
      if (user) {
        analytics.track('cp_payment_fiat', {
          $email: user.email,
          $phone: user.phone,
          chain: config.chainId,
          address: await get(userAddressStore),
          location: window.location.href,
          tokens: purchaseConfig?.tokens,
          memberships: purchaseConfig?.memberships,
          total: priceCents / 100,
          description: (purchaseConfig?.title ?? '') + `(${purchaseConfig?.subtitle ?? ''})`,
        })
      }

      await waitVideoEnded()

      if (priceCents === 0) pageOptsStore.set({ subtitle: 'Your claim was successful!', receipt: false })
      pageStore.set(Pages.PAYMENT_RESULT)
    } catch (err) {
      toastAlert(`Payment error: ${err.message || err}`, 'failure')
      pageOptsStore.set({ errorMessage: err.message })
      pageStore.set(Pages.ERROR)
      dispatch(PassportEvents.ERROR, { error: err })
    }
  }
</script>

<div class="crtw-text-base crtw-mb-1.5">Credit card details</div>
<div
  id="cpui-card-element"
  class="crtw-border crtw-border-gray-300 crtw-rounded crtw-px-2.5 crtw-py-4 crtw-mb-2.5 crtw-w-full"
  style={isCardFocused ? 'border-color: #121e2b' : ''}
/>
<!-- Used to display Element errors. -->
<div id="card-errors" role="alert" />
<ButtonBlack
  text={`Pay $${(priceCents / 100).toFixed(2)}`}
  onClick={handleSubmit}
  disabled={!isButtonActive || isLoading || !isEmailValid || isPriceLoading}
  {isLoading}
/>

{#if stripe && stripeElements}
  <WalletPay {doPayment} {stripe} {stripeElements} {priceCents} bind:isLoading />
{/if}
