import type { TPassportEvent, TPassportMapArgs, TPassportEventCallbackFn } from '@lib/events/events.types'
export { PassportEvents } from '@lib/events/events.enums'

const passportEventsMap = new Map<TPassportEvent, TPassportMapArgs[]>()
let id: number = 0

function off(eventName: TPassportEvent, id: number) {
  const list = passportEventsMap.get(eventName)
  if (!list?.length) return

  for (let i = list.length - 1; i >= 0; i--) {
    if (list[i].id === id) {
      list.splice(i, 1)
      passportEventsMap.set(eventName, list)
      return
    }
  }
}

export function once(eventName: TPassportEvent, callback: TPassportEventCallbackFn) {
  const list = passportEventsMap.get(eventName) ?? []
  id = id + 1
  list.push({ callback, id, once: true })
  passportEventsMap.set(eventName, list)
  return () => off(eventName, id)
}

export function on(eventName: TPassportEvent, callback: TPassportEventCallbackFn) {
  const list = passportEventsMap.get(eventName) ?? []
  id = id + 1
  list.push({ callback, id })
  passportEventsMap.set(eventName, list)
  return () => off(eventName, id)
}

export function dispatch(eventName: TPassportEvent, data?: unknown) {
  const list = passportEventsMap.get(eventName)
  if (!list?.length) return

  for (const evt of list) {
    evt.callback(data)
    if (evt.once) off(eventName, evt.id)
  }
}
