<script lang="ts">
  import { Pages } from '@src/Passport.enums'
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { ethers } from '@credenza3/core-web-evm-ext'
  import ButtonWhite from '@components/inputs/button-white/ButtonWhite.svelte'
  import { PaymentViews } from '@pages/payment/Payment.enums'
  import Stripe from '@pages/payment/stripe/Stripe.svelte'
  import CredenzaBalance from '@pages/payment/credenza-balance/CredenzaBalance.svelte'
  import LockIcon from '@images/lock.svg'
  import ButtonBack from '@components/inputs/button-back/ButtonBack.svelte'

  import {
    pageOptsStore,
    providerStore,
    contractsStore,
    userStore,
    userAddressStore,
    pageStore,
    fn,
    configStore,
  } from '@src/stores/passport-store'
  import { DEFAULT_TOKEN_ID } from '@src/Passport.constants'
  import { dispatch, PassportEvents } from '@lib/events/events'
  import { TPaymentItem } from './Payment.types'

  const { close } = get(fn)
  const chainId = get(configStore).chainId

  $: if ($configStore.chainId !== chainId) close()

  let activeTab = PaymentViews.CREDIT_CARD
  let totalSumCents: number = 0
  let totalSumToken: number = 0
  let isPriceLoading: boolean = false
  let isLoading: boolean = false

  let pricesByToken: { [key: string]: number } = {}

  const getTokenPrice = async (sellableContract: ethers.Contract, tokenId: number): Promise<[number, number]> => {
    let priceCents = await sellableContract.getPriceFiat(tokenId).then((result) => Number(result))
    let priceToken = 0

    if (priceCents === 0) {
      ;[priceCents, priceToken] = await Promise.all([
        sellableContract.getPriceFiat(DEFAULT_TOKEN_ID).then((result) => Number(result)),
        sellableContract.getPriceToken(DEFAULT_TOKEN_ID).then((result) => Number(result)),
      ])
    } else {
      priceToken = await sellableContract.getPriceToken(tokenId).then((result) => Number(result))
    }

    return [priceCents, priceToken]
  }

  const calculateTotalPrice = async (items: TPaymentItem[]): Promise<void> => {
    try {
      isPriceLoading = true

      for (const token of items) {
        if (token.contractAddress === $contractsStore.erc20Cred.address) {
          totalSumCents += token.amount * 100
        } else {
          const { credenzaSellableMinAbi } = await import('@src/Passport.abi')
          const sellableContract = new ethers.Contract(token.contractAddress, credenzaSellableMinAbi, $providerStore)
          const tokenId = token.tokenId ? token.tokenId : (token.typeId as string)

          let [priceCents, priceToken] = await getTokenPrice(sellableContract, +tokenId)

          if (token?.amount && token?.amount !== 0) {
            priceCents *= token.amount
            priceToken *= token.amount
          }

          totalSumCents += priceCents
          totalSumToken += priceToken

          pricesByToken[`${token.contractAddress}/${tokenId}`] = priceToken
        }
      }
    } catch (err) {
      dispatch(PassportEvents.ERROR, err)
    } finally {
      isPriceLoading = false
    }
  }

  const unsub = pageOptsStore.subscribe(async (store) => {
    if (!store?.tokens?.length && !store?.memberships?.length) return

    await calculateTotalPrice([...(store?.tokens || []), ...(store?.memberships || [])])
  })

  onDestroy(unsub)
</script>

{#if isLoading}
  {#await import('@components/progress/ProgressVideo.svelte') then module}
    <svelte:component this={module.default} />
  {/await}
{/if}
<div class="cpui-payment crtw-text-left crtw-w-full" class:crtw-hidden={isLoading}>
  <div class="crtw-text-right crtw-text-[13px] crtw-text-gray-400 crtw-mb-6">
    <div>
      {#await $userAddressStore then address}
        {address}
      {/await}
    </div>
    <div>{$userStore?.email || $userStore?.pending?.email || ''}</div>
  </div>
  <div class="crtw-font-bold crtw-text-xl">{$pageOptsStore.title || `Order Summary`}</div>
  <div class="crtw-flex crtw-justify-between crtw-mb-3 crtw-text-gray-600 crtw-font-bold">
    {#if $pageOptsStore.subtitle}
      <div>{$pageOptsStore.subtitle}</div>
    {/if}
    {#if isPriceLoading}
      <div>Getting price...</div>
    {:else if totalSumCents}
      <div>${(+totalSumCents / 100).toFixed(2)}</div>
    {/if}
  </div>
  <div class="crtw-flex crtw-bg-gray-200 crtw-rounded-md crtw-p-px">
    <ButtonWhite
      text="Credit Card"
      onClick={() => (activeTab = PaymentViews.CREDIT_CARD)}
      isActive={activeTab === PaymentViews.CREDIT_CARD}
      disabled={$pageOptsStore.payments?.stripe?.disabled}
    />
    <ButtonWhite
      text="Credenza Balance"
      onClick={() => (activeTab = PaymentViews.CREDENZA_BALANCE)}
      isActive={activeTab === PaymentViews.CREDENZA_BALANCE}
      disabled={!totalSumCents || $pageOptsStore.payments?.credenzaStoredValue?.disabled}
    />
  </div>
  <hr class="crtw-block crtw-border-t-2 crtw-border-gray-200 crtw-w-1/2 crtw-mx-auto crtw-mt-3.5 crtw-mb-[30px]" />
  {#if activeTab === PaymentViews.CREDIT_CARD}
    <Stripe priceCents={totalSumCents} {isPriceLoading} purchaseConfig={$pageOptsStore} bind:isLoading />
  {:else}
    <CredenzaBalance
      {pricesByToken}
      totalPriceToken={totalSumToken}
      {isPriceLoading}
      purchaseConfig={$pageOptsStore}
      bind:isLoading
    />
  {/if}
  <div class="crtw-flex crtw-w-full crtw-justify-center crtw-items-center">
    <img src={LockIcon} alt="" /><span class="crtw-text-sm crtw-text-gray-400 crtw-ml-1"
      >Payments are secure and encrypted</span
    >
  </div>
  <div class="crtw-mt-1.5">
    <ButtonBack onClick={() => pageStore.set(Pages.WALLET)} />
  </div>
</div>
