import { CredenzaSDK } from '@credenza3/core-web'
import { OAuthExtension } from '@credenza3/core-web-oauth-ext'
import { AccountExtension } from '@credenza3/core-web-account-ext'
import { EvmExtension } from '@credenza3/core-web-evm-ext'
import { getChainConfig } from './chain.config'
import { configStore } from '@stores/passport-store'
import { get } from 'svelte/store'
import { PassportEvents } from '@lib/events/events.enums'
import { dispatch } from '@lib/events/events'
import { analytics } from '@lib/mixpanel/mixpanel'

export let sdk: CredenzaSDK

export const initCredenzaSDK = async () => {
  if (sdk) return
  const { chainId, clientId, auth } = get(configStore)
  const chainConfig = getChainConfig(chainId)

  const evmExtensions = []
  if (auth?.metamask !== false && !!window.ethereum) {
    const { MetamaskExtension } = await import('@credenza3/core-web-evm-metamask-ext')
    evmExtensions.push(new MetamaskExtension())
  }

  sdk = new CredenzaSDK({
    clientId,
    extensions: [
      new EvmExtension({ chainConfig, extensions: evmExtensions }),
      new OAuthExtension(),
      new AccountExtension(),
    ],
  })

  const initSdkPromise = sdk.initialize()

  sdk.on(PassportEvents.LOGIN, async () => {
    await initSdkPromise
    const user = await sdk.account.info()
    const provider = await sdk.evm.getEthersProvider()
    const signer = await provider.getSigner()
    void analytics.track('cp_login', {
      $email: user.email,
      $phone: user.phone,
      chain: chainId,
      provider,
      address: await signer.getAddress(),
      location: window.location.href,
    })
    dispatch(PassportEvents.LOGIN, { provider })
  })

  await initSdkPromise
}
