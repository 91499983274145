<script lang="ts">
  import { get } from 'svelte/store'
  import { Pages } from '@src/Passport.enums'
  import { getIconAttrs } from '@lib/images/images'
  import MenuItem from '@nav/components/menu-item/MenuItem.svelte'
  import BalanceMenuItem from '@nav/components/balance-menu-item/BalanceMenuItem.svelte'
  import { erc20CredTokens } from '@src/config'

  import SignoutIcon from '@nav/images/signout.svg'
  import AccountIcon from '@nav/images/account.svg'
  import QrCodeIcon from '@nav/images/qrcode.svg'
  import ScanIcon from '@images/scanner.svg'

  import type { TTheme } from '@src/Passport.types'
  import { fn, configStore } from '@stores/passport-store'

  export let isMinimized: boolean = false
  export let theme: TTheme

  const { chainId, buyTokens } = get(configStore)
  const { openUI } = get(fn)

  const iconStyles = Object.entries(getIconAttrs(theme))
    .map(([key, value]) => `${key}:${value}`)
    .join(';')
</script>

<MenuItem {isMinimized} {theme} title="Sign Out" onClick={() => openUI(Pages.LOGOUT)}>
  <img src={SignoutIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
<MenuItem {isMinimized} {theme} title="Show ID" onClick={() => openUI(Pages.PASSPORT_ID)}>
  <img src={QrCodeIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
<MenuItem {isMinimized} {theme} title="PassScan" onClick={() => openUI(Pages.SCANNER)}>
  <img src={ScanIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
{#if buyTokens !== false && erc20CredTokens[chainId]?.address}
  <BalanceMenuItem {isMinimized} {theme} style={iconStyles} />
{/if}
<MenuItem {isMinimized} {theme} title="My Account" onClick={() => openUI(Pages.PROFILE)}>
  <img src={AccountIcon} style={iconStyles} slot="icon" alt="" />
</MenuItem>
