import { SvelteComponent } from 'svelte'
import { toast } from '@zerodevx/svelte-toast/dist'

import { pageOptsStore, pageStore } from '@stores/passport-store'
import { getToastTheme, createToastRoot, getMessageStyles } from '@lib/toast/toast'

import type { TPage, TNavConfig, TToastMessageOpts } from '@src/Passport.types'

let appRoot: SvelteComponent | null
let appRootElement: HTMLElement | null
let navRoot: SvelteComponent | null
let navRootElement: HTMLElement | null
let toastRoot: SvelteComponent | null
let passportMessageId: number

export const destroy = async () => {
  toastRoot?.$destroy()
  appRoot?.$destroy()
  navRoot?.$destroy()
  navRootElement = appRootElement = appRoot = navRoot = toastRoot = null
}

export const close = () => {
  if (appRootElement) appRootElement.style.display = 'none'
  pageStore.set(null)
}

export const openUI = async (page?: TPage, pageOpts?: unknown) => {
  if (pageOpts) pageOptsStore.set(pageOpts)
  if (page) pageStore.set(page)
  if (!appRootElement) {
    const App = (await import('@app/App.svelte')).default
    appRoot = new App({ target: document.body })
    appRootElement = document.getElementById('cpui') as HTMLElement
  } else if (appRootElement) appRootElement.style.removeProperty('display')
}

export const hideNavigation = () => {
  if (navRootElement) navRootElement.style.display = 'none'
}

export const showNavigation = async (styles = {}, navConfig: TNavConfig = {}) => {
  if (!navRootElement) {
    const Nav = (await import('@nav/Nav.svelte')).default
    navRoot = new Nav({
      target: document.body,
      props: { styles, navConfig },
    })
    navRootElement = document.getElementById('cpnav') as HTMLElement
  } else {
    navRoot?.$set({ styles, navConfig })
    if (navRootElement) navRootElement.style.removeProperty('display')
  }
}

export const toastAlert = (
  message: string,
  type: 'success' | 'warning' | 'failure' | 'info' = 'info',
  opts?: TToastMessageOpts,
) => {
  if (!toastRoot) toastRoot = createToastRoot()
  const { duration, centered } = opts || {}
  const props = {
    theme: {
      ...getToastTheme(type),
      ...(centered?.enabled && getMessageStyles(centered?.position)),
    },
    ...(duration === -1 ? { initial: 0 } : { duration: duration || 10000 }),
  }

  if (passportMessageId && centered?.enabled) {
    toast.pop(passportMessageId)
  }
  const id = toast.push(message, props)
  // renew message bar id
  if (centered?.enabled) passportMessageId = id
}
