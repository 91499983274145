<script lang="ts">
  export let text: string | undefined = undefined
  export let description: string | undefined = undefined
</script>

<div
  class="crtw-flex crtw-flex-col crtw-items-center crtw-mx-auto crtw-text-center crtw-text-textMain
   crtw-max-w-xs crtw-relative sm:crtw-max-w-[360px]"
>
  <p class="crtw-font-bold crtw-text-[21px] crtw-uppercase sm:crtw-text-2xl sm:crtw-mb-px">
    {text ?? ``}
  </p>
  <p class="crtw-text-sm sm:crtw-text-base">
    {description ?? ``}
  </p>

  <slot />
</div>
