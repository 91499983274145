import { get } from 'svelte/store'

import { analytics } from '@lib/mixpanel/mixpanel'
import { PassportEvents, dispatch } from '@lib/events/events'
import { userStore, isLoggedInStore, configStore, userAddressStore, accessTokenStore } from '@stores/passport-store'
import { PassportLocalStorage } from '@src/Passport.enums'

import { sdk } from '@lib/oauth/oauth'
import { AUTH_PROVIDERS, OAUTH_LOGIN_TYPE, OAUTH_PASSWORDLESS_TYPES } from '@lib/oauth/oauth.constants'
import { Passport } from '@src/Passport'

export async function login(
  this: Passport,
  provider: AUTH_PROVIDERS,
  oauthType?: OAUTH_LOGIN_TYPE,
  passwordlessType?: OAUTH_PASSWORDLESS_TYPES,
) {
  void analytics.track('cp_login_initiated', {
    chain: this.config.chainId,
    provider: provider,
    location: window.location.origin + window.location.pathname,
  })

  try {
    if (provider === AUTH_PROVIDERS.METAMASK) {
      await sdk.evm.metamask.login()
    } else if (provider === AUTH_PROVIDERS.OAUTH) {
      await sdk.oauth.login({
        scope: 'profile profile.write email phone blockchain.evm.write blockchain.evm',
        redirectUrl: window.location.origin + window.location.pathname,
        ...(oauthType && { type: oauthType }),
        ...(passwordlessType && { passwordlessType }),
      })
    } else {
      throw new Error('Unknown provider')
    }
    this.init()
  } catch (err) {
    dispatch(PassportEvents.ERROR, { provider, error: err })
    throw err
  }
}

export async function logout({ shouldRevokeSession = false } = {}) {
  try {
    const config = get(configStore)
    const user = get(userStore)
    analytics.track('cp_logout', {
      chain: config.chainId,
      address: await get(userAddressStore),
      location: window.location.href,
      $email: user?.email,
      $phone: user?.phone,
    })
  } catch {
    /**/
  }

  localStorage.removeItem(PassportLocalStorage.QR_CODE)
  isLoggedInStore.set(false)
  userStore.set(null)
  accessTokenStore.set(null)
  if (shouldRevokeSession) {
    await sdk.oauth.revokeSession()
  }
  sdk.logout()
  dispatch(PassportEvents.LOGOUT)
}
