<style lang="scss">
  :global(body) {
    --w3m-z-index: 1000001;
  }
</style>

<script lang="ts">
  import Header from '@components/header/Header.svelte'
  import Footer from '@components/footer/Footer.svelte'

  import { Pages } from '@src/Passport.enums'
  import { pageStore, forcedPage, cloakStore, ppaStore } from '@src/stores/passport-store'
  import Container from '@components/container/Container.svelte'
</script>

<div id="cpui" class="crdz-ui">
  <div
    class="crtw-font-['Poppins'] crtw-fixed crtw-z-50 crtw-top-0 crtw-left-0 crtw-min-w-screen crtw-w-full
    crtw-min-h-screen crtw-h-full crtw-flex crtw-justify-center crtw-items-center crtw-bg-black/70
    crtw-text-[#121e2b]"
    class:backdrop-blur-md={$cloakStore || $ppaStore.active}
  >
    <div
      class="crtw-bg-white crtw-rounded-lg crtw-relative crtw-w-full crtw-overflow-y-scroll
      crtw-max-w-[380px] crtw-max-h-[650px] sm:crtw-max-w-md"
    >
      <Header />
      <Container class="crtw-pt-11 crtw-pb-9 sm:crtw-pt-[50px] crtw-px-[22px]">
        <div
          class="crtw-flex crtw-flex-col crtw-items-center crtw-justify-center crtw-bg-white crtw-rounded
           crtw-min-h-[155px] crtw-gap-6"
        >
          {#if $forcedPage === Pages.LOGIN || $pageStore === Pages.LOGIN}
            {#await import('@pages/login/Login.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.LOGOUT}
            {#await import('@pages/logout/Logout.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $forcedPage === Pages.CONFIRM_ACCOUNT || $pageStore === Pages.CONFIRM_ACCOUNT}
            {#await import('@pages/confirm-account/ConfirmAccount.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $forcedPage === Pages.UPDATE_PROFILE || $pageStore === Pages.UPDATE_PROFILE}
            {#await import('@pages/update-profile/UpdateProfile.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.PASSPORT_ID}
            {#await import('@pages/passport-id/PassportId.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.WALLET}
            {#await import('@pages/wallet/Wallet.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.SEND_TOKENS}
            {#await import('@pages/send-tokens/SendTokens.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.PAYMENT}
            {#await import('@pages/payment/Payment.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.PAYMENT_RESULT}
            {#await import('@pages/payment-result/PaymentResult.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.SCANNER}
            {#await import('@pages/scanner/Scanner.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.ERROR}
            {#await import('@pages/error/Error.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else if $pageStore === Pages.LOADER}
            {#await import('@components/progress/ProgressVideo.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {:else}
            {#await import('@pages/profile/Profile.svelte') then module}
              <svelte:component this={module.default} />
            {/await}
          {/if}
        </div>
        <Footer />
      </Container>
    </div>
  </div>
</div>
