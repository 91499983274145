import { SvelteToast } from '@zerodevx/svelte-toast/dist'

const themes = {
  success: {
    '--toastBackground': '#2E8B57',
    '--toastBarBackground': 'green',
  },
  warning: {
    '--toastBackground': 'orange',
    '--toastBarBackground': '#FFC300',
  },
  failure: {
    '--toastBackground': 'red',
    '--toastBarBackground': '#C70039',
  },
  info: {
    '--toastBackground': '#353935',
    '--toastBarBackground': '#28282B',
  },
}
export const getMessageStyles = (position: 'top' | 'bottom' | undefined) => ({
  position: 'fixed',
  left: '50%',
  right: '50%',
  width: '100%',
  transform: 'translate(-50%, 0)',
  'max-width': '450px',
  '--toastWidth': '100%',
  ...(position === 'bottom' ? { bottom: '25px' } : { top: '25px' }),
})

export const getToastTheme = (type: 'success' | 'warning' | 'failure' | 'info') => themes[type]

export const createToastRoot = () => {
  createToastStyles()

  const toastRoot = new SvelteToast({ target: document.body })
  const toastContainer = document.querySelector('._toastContainer')
  toastContainer?.classList.add('credenza-toast-container')
  return toastRoot
}

const createToastStyles = () => {
  if (!document.getElementById('credenzaToastStyles')) {
    const styleSheet = document.createElement('style')
    styleSheet.id = 'credenzaToastStyles'
    styleSheet.innerText = `
      @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
      .credenza-toast-container{ 
        width: 100%;
        height: 100%;
        font-family: Poppins, serif;
        font-weight: 400;
        color: #fff;
        z-index: 1000001 !important;
      }
      .credenza-toast-container li {
        font-size: 12px;
        word-break: break-word;
        display: flex;
        justify-content: right;
        align-items: center;
      }
      .credenza-toast-container li a {
        color: #fff; 
      }
      .credenza-toast-container li a:link,
      .credenza-toast-container li a:visited,
      .credenza-toast-container li a:-webkit-any-link {
        color: #fff;
      }
    `
    document.head.appendChild(styleSheet)
  }
}
