import { get } from 'svelte/store'
import { configStore, accessTokenStore, providerStore, userAddressStore } from '@stores/passport-store'
import { ethers } from '@credenza3/core-web-evm-ext'
import { TRUSTED_FORWARDER } from '@src/Passport.constants'
import { CHAIN_INFO } from '@src/Passport.chains'

const isGaslessTxSupported = async (contract: ethers.Contract) => {
  try {
    const trustedForwarder = await contract.getTrustedForwarder()
    return trustedForwarder.toLowerCase() === TRUSTED_FORWARDER
  } catch (err) {
    console.warn('Gasless tx are not available for this contract', err?.message || err)
    return false
  }
}

export const sendContractTx = async (tx: ethers.ContractTransaction, contract: ethers.Contract) => {
  if (!(await isGaslessTxSupported(contract))) {
    const signer = await get(providerStore).getSigner()
    const response = await signer.sendTransaction(tx)
    return response
  }

  const { credenza, chainId } = get(configStore)
  const txBytes = ethers.Transaction.from(tx).unsignedSerialized

  const result = await fetch(`${credenza.apiUrl}/chains/${chainId}/sendTransaction`, {
    method: 'POST',
    body: JSON.stringify({ tx: txBytes, from: await get(userAddressStore) }),
    headers: {
      Authorization: `Bearer ${get(accessTokenStore)}`,
      'Content-Type': 'application/json',
    },
  })
  if (!result.ok) throw new Error(result.statusText)

  const json = await result.json()
  return {
    ...json.tx,
    wait: async () => get(providerStore).waitForTransaction(json.tx.hash),
  }
}

export const getTxScanAddress = (txHash: string) => {
  const { chainId } = get(configStore)
  return CHAIN_INFO[chainId].scanUrl + '/tx/' + txHash
}
