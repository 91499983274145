import { get } from 'svelte/store'
import { accessTokenStore, configStore } from '@stores/passport-store'
import { RecieptTarget } from '@src/Passport.enums'

export const requestReceipt = async ({ hash, stripeChargeId }: { hash?: string; stripeChargeId?: string }) => {
  const { chainId, credenza, recieptTarget } = get(configStore)

  const buildPaymentObject = hash
    ? { payment: { transaction: { chainId, hash } } }
    : { payment: { stripe: { chargeId: stripeChargeId } } }

  const result = await fetch(`${credenza.apiUrl}/payments/receipt`, {
    method: 'POST',
    body: JSON.stringify({
      ...buildPaymentObject,
      target: recieptTarget || RecieptTarget.EMAIL,
      ...(import.meta.env.PASSPORT_BRAND_LABEL ? { header: import.meta.env.PASSPORT_BRAND_LABEL } : {}),
    }),
    headers: {
      Authorization: `Bearer ${get(accessTokenStore)}`,
      'Content-Type': 'application/json',
    },
  })

  return result.ok
}
